import { Link } from "react-router-dom"
import "../styles.css"
import { Helmet } from "react-helmet-async"
import guideIchra from "../../../assets/guide-to-ichra.svg"
import guideQsehra from "../../../assets/guide-salusions.svg"
import ResourceCenter from "../../../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../../../components/Resource-Center/ResourceCenterVideo"

export const question = "What is a PCORI fee?"

export default function LearningEntry() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": [
			{
				"@type": "Question",
				"name": "What is a PCORI fee?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "The ACA imposes a fee on employers that offer certain types of self-insured health plans, which includes QSEHRA and ICHRA, to help fund the Patient-Centered Outcomes Research Institute (PCORI).",
					"dateCreated": "2024-10-05T14:30:00+00:00",
				},
				"datePublished": "2024-10-05T14:30:00+00:00",
				"dateModified": "2024-10-05T14:30:00+00:00",
			},
		]
	}

	return (
		<>
			<Helmet>
				<title>Salusion - Employer Resources</title>
				<meta name="title" content="FAQ: What is a PCORI fee?" />
				<meta name="description" content="The ACA imposes a fee on employers that offer certain types of self-insured health plans, which includes QSEHRA and ICHRA, to help fund the Patient-Centered Outcomes Research Institute (PCORI)." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>

			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							<b>
								Note: The below is informational only. We are not licensed tax professionals, and this information is
								not to be used as tax guidance. Please consult with your licensed tax professional on the usage of this
								information, calculation of fees, and completion of forms.
							</b>
						</p>

						<p className="text">
							The ACA imposes a fee on employers that offer certain types of self-insured health plans, which includes
							QSEHRA and ICHRA, to help fund the Patient-Centered Outcomes Research Institute (PCORI).
						</p>

						<p className="text">About the fee:</p>

						<ul>
							<li>It is required to be reported only once a year in July.</li>
							<li>It is reported on IRS Form 720.*</li>

							<li>
								For plans that ended December 2022, the PCORI fee is $3.00 per employee, and it is due July 31, 2023. *
							</li>
						</ul>

						<p className="text">
							<i>
								*Although Form 720 is a quarterly return, for PCORI, Form 720 is filed annually only, by July 31. If you
								have another reason to file the Form 720 on a quarterly basis, you only need to report the PCORI fee on
								the second quarter form, due July 31st.
							</i>
						</p>

						<p className="text">
							<b>How do I calculate my PCORI fee?</b>
							<br />
							The fee is per covered life, based on the average number of covered lives throughout the plan year. The
							IRS allows the plan sponsor to calculate average covered lives using a few different methods, and we
							provide information for you to use the Snapshot Method (counting covered lives around the same date once a
							quarter).
						</p>

						<p className="text">
							For an HRA, covered lives includes employees (not spouses or dependents) that have not waived coverage.
							You can look at your Salusion monthly invoice to check how many covered lives you had during any month.
						</p>

						<p className="text">
							Also, note that if even if you do not owe a fee (if you had zero covered lives during the year), you do
							still have to mail in your completed and signed Form 720.
						</p>

						<p className="text">
							<b>Does Salusion help me report and pay the PCORI fee?</b>
							<br />
							Yes! We are here to help. To assist you in complying with this requirement, we will provide to you in June
							each year:
						</p>

						<ol>
							<li>
								an estimate of your average number of lives covered by your HRA during your plan year based on the
								information in our system, and
							</li>
							<li>some information about filing IRS Form 720 and paying the fee.</li>
						</ol>

						<p className="text">
							<b>How do I file my Form 720 & pay my PCORI fee?</b>
							<br />
							To file your Form 720, mail the completed Form 720, along with Form 720-V (Payment Voucher), and a check
							or money order for the total owed to the IRS:
						</p>

						<p className="text">
							Department of the Treasury
							<br />
							Internal Revenue Service
							<br />
							Ogden, UT 84201-0009
						</p>

						<p className="text">
							If you prefer, you can pay electronically through EFTPS. Electronic payments must be initiated before July
							29th 8:00pm EST. Note that you'll still need to mail in your Form 720 even if you pay electronically.
						</p>

						<p className="text">For more detailed information, see the IRS Instructions for Form 720.</p>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideIchra}
					title="A flexible, low-cost alternative to group coverage"
					pageLink="/product/ichra/video-guide"
				/>
				<ResourceCenterVideo
					resourceImg={guideQsehra}
					title="Provide tax-advantaged benefits simply and affordably with Salusion"
					pageLink="/product/qsehra/video-guide"
				/>
			</ResourceCenter>
		</>
	)
}
