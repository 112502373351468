import { Link } from "react-router-dom"
import "../styles.css"
import { Helmet } from "react-helmet-async"
import guideIchra from "../../../assets/guide-to-ichra.svg"
import guideQsehra from "../../../assets/guide-salusions.svg"
import guideEbhra from "../../../assets/guide-ebhra.svg"
import guideGchra from "../../../assets/guide-gchra.svg"
import ResourceCenter from "../../../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../../../components/Resource-Center/ResourceCenterVideo"

export const question = "How do owners of S corporations deduct their health insurance premiums?"

export default function LearningEntry() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": [
			{
				"@type": "Question",
				"name": "How do owners of S corporations deduct their health insurance premiums?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Owners of S corporations can deduct their health insurance premiums in a way that provides tax benefits.",
					"dateCreated": "2024-10-05T14:30:00+00:00",
				},
				"datePublished": "2024-10-05T14:30:00+00:00",
				"dateModified": "2024-10-05T14:30:00+00:00",
			},
		]
	}
	return (
		<>
			<Helmet>
				<title>Salusion - Employer Resources</title>
				<meta name="title" content="FAQ: How do owners of S corporations deduct their health insurance premiums?" />
				<meta name="description" content="Owners of S corporations can deduct their health insurance premiums in a way that provides tax benefits." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>

			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							Owners of S corporations can deduct their health insurance premiums in a way that provides tax benefits.
							The process involves treating the health insurance premiums as a business expense, and the deduction is
							taken at the individual level. Here are the key steps:
						</p>

						<ol>
							<li>
								Eligibility for the Deduction
								<ul>
									<li>The owner of an S corporation must be considered an employee of the business.</li>

									<li>
										The health insurance plan must be established under the business, and the owner-employee must be
										eligible for the plan.
									</li>
								</ul>
							</li>

							<li>
								Inclusion in W-2 Income
								<ul>
									<li>
										The S corporation should include the health insurance premiums paid on behalf of the owner in their
										W-2 income.
									</li>

									<li>
										The premiums are reported as wages, but they are not subject to Social Security or Medicare taxes.
									</li>
								</ul>
							</li>

							<li>
								Personal Income Tax Deduction
								<ul>
									<li>
										The owner-employee can then deduct the health insurance premiums on their individual income tax
										return.
									</li>

									<li>
										This deduction is taken on the owner's Form 1040, typically on the "Health Savings Account, Archer
										MSA, or Medicare Advantage MSA" line, and it is an "above-the-line" deduction. This means the
										deduction is available even if the owner doesn't itemize deductions.
									</li>
								</ul>
							</li>

							<li>
								Limitations
								<ul>
									<li>
										The deduction is subject to certain limitations, such as the owner's net income from the S
										corporation. In general, the deduction cannot exceed the owner's earned income from the business.
									</li>

									<li>There may be additional limitations based on the overall cost of the health insurance plan.</li>
								</ul>
							</li>
						</ol>

						<p className="text">
							It's important for S corporation owners to work closely with their tax advisors to ensure compliance with
							tax regulations and to maximize the benefits of the health insurance premium deduction. Tax laws can
							change, so staying informed about the latest regulations is crucial for accurate tax planning.
						</p>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideIchra}
					title="A flexible, low-cost alternative to group coverage"
					pageLink="/product/ichra/video-guide"
				/>
				<ResourceCenterVideo
					resourceImg={guideQsehra}
					title="Provide tax-advantaged benefits simply and affordably with Salusion"
					pageLink="/product/qsehra/video-guide"
				/>
				<ResourceCenterVideo
					resourceImg={guideGchra}
					title="Simple, Affordable GCHRAs"
					pageLink="/product/gchra"
				/>
				<ResourceCenterVideo
					resourceImg={guideEbhra}
					title="Simple, Affordable EBHRAs"
					pageLink="/product/ebhra"
				/>

			</ResourceCenter>

		</>
	)
}
