import React from "react"

export default function FormButtons(props) {
	const { step, setStep, hideNext, children } = props
	const { backLabel, onBackStep, nextLabel, onNextStep } = props

	function handleBack() {
		if (onBackStep) onBackStep()
		else setStep(step - 1)
	}

	return (
		<div className="button-wrapper">
			{step > 0 && (
				<button type="button" className="btn-back" onClick={handleBack}>
					{backLabel || "Back"}
				</button>
			)}

			{!hideNext && (
				<button type="submit" className="btn-next" onClick={onNextStep}>
					{nextLabel || "Next"}
				</button>
			)}

			{children}
		</div>
	)
}
