export default function BenefitsEmployerNumber({ number }) {
	return (
		<div className="col-12 col-lg-6 col-md-12">
			<div className="benefits-number-wrapper">
				<h2>{number}</h2>
				<h4>Employers</h4>
			</div>
		</div>
	)
}
