/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { useCalendlyEventListener, InlineWidget } from "react-calendly"
import { sendVisitorInfo, createPipedriveDeal } from "../../util"

export default function Calendly(props) {
	const { calendarOnly } = props

	const location = useLocation()
	const searchParams = useSearchParams()[0]

	const [hideLandingPage, setHideLandingPage] = useState(() => false)
	const [hideEventType, setHideEventType] = useState(() => false)

	useCalendlyEventListener({
		onEventScheduled: async (event) => {
			try {
				const eventURL = event.data.payload.event.uri
				const inviteeURL = event.data.payload.invitee.uri
				const calendly = { eventURL, inviteeURL }

				const route = location.pathname
				const page = route.split("/")[1]

				let product = null
				let title = "Website"
				let dealSource = "Website"

				if (page === "product") {
					product = route.split("/")[2].toUpperCase()
					title = `Website Product ${product}`
					dealSource = "Website Product"
				}

				const payload = { title, websiteURL: route, dealSource, product, calendly }
				await createPipedriveDeal(payload)

				const visitorInfo = getVisitorInfo()
				await sendVisitorInfo(visitorInfo)
			} catch (error) {
				console.error(error)
			}
		}
	})

	function getVisitorInfo() {
		const route = "/calendly"
		const payload = { url: route }

		for (const [key, value] of searchParams) {
			payload[key] = value
		}

		if (route.startsWith("/product") && route.split("/")[2]) {
			payload.product = route.split("/")[2].toUpperCase()
		}

		return payload
	}

	function updateCalendlyEmbed() {
		if (window.innerWidth < 1200) {
			setHideLandingPage(true)
			setHideEventType(true)
		} else {
			setHideLandingPage(false)
			setHideEventType(false)
		}
	}

	useEffect(() => {
		if (calendarOnly) {
			setHideLandingPage(true)
			setHideEventType(true)
		} else {
			updateCalendlyEmbed()
			window.addEventListener("resize", updateCalendlyEmbed)

			return () => window.removeEventListener("resize", updateCalendlyEmbed)
		}
	}, [])

	return (
		<InlineWidget
			url="https://calendly.com/salusion-inquiry/welcome-to-salusion"
			pageSettings={{
				hideLandingPageDetails: hideLandingPage,
				hideEventTypeDetails: hideEventType,
				hideGdprBanner: true
			}}
		/>
	)
}
