export default {
	section: {
		position: "relative"
	},

	container: {
		margin: "100px auto"
	},

	header: {
		margin: "unset",
		textAlign: "left"
	},

	text: {
		margin: "unset",
		fontSize: "18px"
	}
}
