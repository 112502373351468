import Stack from "react-bootstrap/Stack"
import styles from "./styles"

export default function Instructions() {
	return (
		<section style={styles.section}>
			<div id="referral" className="container" style={styles.container}>
				<Stack gap={4} className="hra-wrapper">
					<Stack gap={3}>
						<h2 style={styles.header}>Getting Started</h2>

						<p style={styles.text}>
							If you already have a Salusion account, log in and go to the referral section. If you don't have an
							account yet, click the "Create Referral Account" button above to set up your login and password.
						</p>
						<br />
					</Stack>

					<Stack gap={3}>
						<h2 style={styles.header}>How It Works</h2>

						<p style={styles.text}>
							Salusion pays program members $42 per participant for ICHRAs and QSEHRAs, and $15 per participant for
							EBHRAs and GCHRAs. A participant is any employee at the referral company who generates three months of
							fees within the first three months of their initial plan year. Payments will be sent to the referrer's
							account in the third month of the company's initial plan year.
						</p>
						<p style={styles.text}>
							Referrals must be submitted before the referred company creates an account with Salusion, and only one
							referral is allowed per company.
						</p>
					</Stack>
				</Stack>
			</div>
		</section>
	)
}
