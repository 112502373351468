import { useLayoutEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { useVisitorTracking } from "../util"

import MainNavigation from "../components/Header/MainNavigation"
import Footer from "../components/Footer/Footer"

export default function RootLayout() {
	const location = useLocation()
	useVisitorTracking()

	useLayoutEffect(() => {
		document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" })
	}, [location.pathname])

	return (
		<div className="fl-page">
			<MainNavigation />
			<main>
				<Outlet />
			</main>
			<Footer />
		</div>
	)
}
