import { Link } from "react-router-dom"
import "../styles.css"
import { Helmet } from "react-helmet-async"
import guideQsehra from "../../../assets/guide-salusions.svg"
import ichraVsQsehra from "../../../assets/ichravsqsehra-poster.svg"
import qsehraRules from "../../../assets/qsehra-rules.svg"
import ResourceCenter from "../../../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../../../components/Resource-Center/ResourceCenterVideo"

export const question = "What expenses are eligible for reimbursement under a QSEHRA?"

export default function LearningEntry() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": [
			{
				"@type": "Question",
				"name": "What expenses are eligible for reimbursement under a QSEHRA?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "QSEHRAs may reimburse only medical expenses defined in Code §213(d): Employers have discretion to choose which of the Code §213(d) expenses are reimbursable; employees should refer to their Summary Plan Description for limitations.",
					"dateCreated": "2024-10-05T14:30:00+00:00",
				},
				"datePublished": "2024-10-05T14:30:00+00:00",
				"dateModified": "2024-10-05T14:30:00+00:00",
			},
		]
	}
	return (
		<>
			<Helmet>
				<title>Salusion - Employer Resources</title>
				<meta name="title" content="FAQ: What expenses are eligible for reimbursement under a QSEHRA?" />
				<meta name="description" content="QSEHRAs may reimburse only medical expenses defined in Code §213(d): Employers have discretion to choose which of the Code §213(d) expenses are reimbursable; employees should refer to their Summary Plan Description for limitations." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>

			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							<b>Who</b>
							<br />A medical expense must be incurred by the employee, the employee's spouse, an employee's child who
							has not attained age 27 as of the end of the employee's taxable year, or the employee's tax dependent for
							health coverage purposes in order for its reimbursement to be excluded from the employee's income.
						</p>

						<p className="text">
							<b>What</b>
							<br />
							QSEHRAs may reimburse only medical expenses defined in <a href="https://www.law.cornell.edu/uscode/text/26/213">Code §213(d)</a>: Employers have discretion to choose
							which of the Code §213(d) expenses are reimbursable; employees should refer to their Summary Plan
							Description for limitations.
						</p>

						<ul>
							<li>
								The primary definition for “medical care” under Code §213(d), is amounts paid “for the diagnosis, cure,
								mitigation, treatment, or prevention of disease, or for the purpose of affecting any structure or
								function of the body.” In simpler terms, most out-of-pocket medical expenses are eligible for
								reimbursement, including transportation and lodging associated with the medical expenses.
							</li>

							<li>Procedures that are cosmetic or illegal are not eligible.</li>

							<li>
								The <a href="https://www.congress.gov/bill/116th-congress/senate-bill/3548/text">CARES Act</a>, in 2020, further expanded medical care to include over-the-counter medicines (cold and
								flu, sunblock, etc...) and menstrual products.
							</li>

							<li>
								Code §213(d) includes as “medical care” insurance premiums. These are reimbursable under a QSEHRA.
								However, premium costs that are reimbursable by the HRA must not be eligible for payment on a pre-tax
								basis under the employer's cafeteria plan.
							</li>
						</ul>

						<p className="text">
							<b>When</b>
							<br />
							QSEHRAs can only reimburse expenses that are incurred during the plan year.
						</p>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideQsehra}
					title="Provide tax-advantaged benefits simply and affordably with Salusion"
					pageLink="/product/qsehra"
				/>

				<ResourceCenterVideo
					resourceImg={ichraVsQsehra}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>
				<ResourceCenterVideo
					resourceImg={qsehraRules}
					title="Take a deep dive into the rules and regulations that govern QSEHRAs"
					pageLink="/product/qsehra/rules-and-regulations"
				/>
			</ResourceCenter>
		</>
	)
}
