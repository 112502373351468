import Input from "../Input"

export default function CreateAccountHero() {
	return (
		<>
			<section id="create_account" className="first-section first-padding">
				<div className="container">
					<div className="create-account-wrapper white-wrapper-all">
						<h1>Get started with Salusion</h1>
						<p>Take the first step and create a company account at Salusion</p>
						<form className="form-all contact-form create-acc">
							<div className="row">
								<Input type="text" name="company" placeholder="Company" />
								<Input type="text" name="first_name" placeholder="First Name" />
								<Input type="text" name="last_name" placeholder="Last Name" />
								<Input type="email" name="email" placeholder="Email" />
								<Input type="tel" name="phone" placeholder="Phone" />
								<div className="col-12">
									<button type="submit" className="btn-linear">
										Create Account
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</section>
		</>
	)
}
