import HraContact from "../components/Hra/HraContact"
import EmployerUpload from "../components/Employer-Calculator/EmployerUpload"
import EmployerCalculatorAccordion from "../components/Employer-Calculator/EmployerCalculatorAccordion"

import "../components/Employer-Calculator/Employer.css"

export default function EmployerCalculator() {
	return (
		<>
			<section id="employer_calculator_hero" className="first-padding">
				<div className="employer-hero">
					<div className="container">
						<h1>ACA-Compliant ICHRA</h1>
					</div>
				</div>
			</section>
			<EmployerUpload />
			<EmployerCalculatorAccordion />
			<HraContact />
		</>
	)
}
