import { Field, ErrorMessage } from "formik"
import FormButtons from "../../FormButtons"

export default function InsuranceDetails({ step, setStep, dependents }) {
	return (
		<>
			<div className="form-content-wrapper">
				<h2>Add Insurance Details</h2>

				<div className="d-flex">
					<div className="form-group d-flex-50">
						<label>
							<span>Insurance Carrier</span>
							<Field type="text" name="provider" placeholder="Insurance Carrier" />
							<ErrorMessage name="provider" component="div" className="error-message-fields" />
						</label>
					</div>

					<div className="form-group d-flex-50">
						<label>
							<span>Policy ID</span>
							<Field type="text" name="policyId" placeholder="Policy ID" />
							<ErrorMessage name="policyId" component="div" className="error-message-fields" />
						</label>
					</div>

					<div className="form-group d-flex-50">
						<label>
							<span>Monthly Premium</span>
							<Field type="number" name="monthlyPremium" placeholder="$0.00" />
							<ErrorMessage name="monthlyPremium" component="div" className="error-message-fields" />
						</label>
					</div>

					<div className="form-group d-flex-50">
						<label>
							<span>Policy Holder</span>

							<Field as="select" name="policyHolder" placeholder="Policy Holder">
								<option value="" disabled>
									Select Policy Holder
								</option>

								{dependents.map((dependent, index) => (
									<option key={index} value={dependent.id}>
										{dependent.firstName} {dependent.lastName}
									</option>
								))}
							</Field>

							<ErrorMessage name="policyHolder" component="div" className="error-message-fields" />
						</label>
					</div>

					<div className="insurance-details-radio">
						<h3>What type of individual coverage insurance do you have?</h3>

						<div className="radio-buttons">
							<label>
								<Field type="radio" name="insuranceSource" value="MARKETPLACE" />
								<span>Marketplace</span>
							</label>

							<label>
								<Field type="radio" name="insuranceSource" value="OFF_MARKETPLACE" />
								<span>Direct from Insurance Carrier</span>
							</label>
						</div>
					</div>
				</div>
			</div>

			<FormButtons step={step} setStep={setStep} nextLabel="Accept" />
		</>
	)
}
