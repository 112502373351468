import { Link } from "react-router-dom"
import "../styles.css"
import { Helmet } from "react-helmet-async"
import guideQsehra from "../../../assets/guide-salusions.svg"
import ichraVsQsehra from "../../../assets/ichravsqsehra-poster.svg"
import qsehraRules from "../../../assets/qsehra-rules.svg"
import ResourceCenter from "../../../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../../../components/Resource-Center/ResourceCenterVideo"

export const question = "What is the QSEHRA uniformity requirement?"

export default function LearningEntry() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": [
			{
				"@type": "Question",
				"name": "What is the QSEHRA uniformity requirement?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "With the exception of carryovers of unused benefit amounts, the employer cannot vary the amount made available for reimbursements among eligible employees. This is called the 'uniformity requirement'.",
					"dateCreated": "2024-10-05T14:30:00+00:00",
				},
				"datePublished": "2024-10-05T14:30:00+00:00",
				"dateModified": "2024-10-05T14:30:00+00:00",
			},
		]
	}

	return (
		<>
			<Helmet>
				<title>Salusion - Employer Resources</title>
				<meta name="title" content="FAQ: What is the QSEHRA uniformity requirement?" />
				<meta name="description" content="With the exception of carryovers of unused benefit amounts, the employer cannot vary the amount made available for reimbursements among eligible employees. This is called the 'uniformity requirement'." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>

			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							With the exception of carryovers of unused benefit amounts, the employer cannot vary the amount made
							available for reimbursements among eligible employees. This is called the “uniformity requirement”.
						</p>

						<p className="text">
							When applying the uniformity rule, however, a QSEHRA may exclude the following employees (as further
							defined in IRS regulations applicable to self-funded health plans under <a href="https://www.law.cornell.edu/cfr/text/26/1.105-11">Code §105</a>):
						</p>

						<ul>
							<li>Employees who have not completed 90 days of service;</li>
							<li>Employees who have not attained age 25 before the beginning of the plan year;</li>
							<li>Part-time or seasonal employees;</li>

							<li>
								Non-participating employees covered by a collective bargaining agreement (if health benefits were the
								subject of good faith bargaining); and
							</li>

							<li>
								Nonresident aliens who do not receive earned income from the employer from sources within the United
								States.
							</li>
						</ul>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideQsehra}
					title="Provide tax-advantaged benefits simply and affordably with Salusion"
					pageLink="/product/qsehra"
				/>

				<ResourceCenterVideo
					resourceImg={ichraVsQsehra}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>
				<ResourceCenterVideo
					resourceImg={qsehraRules}
					title="Take a deep dive into the rules and regulations that govern QSEHRAs"
					pageLink="/product/qsehra/rules-and-regulations"
				/>
			</ResourceCenter>
		</>
	)
}
