import { useState, createContext } from "react"

export const OnboardingContext = createContext()

export const OnboardingContextProvider = ({ children }) => {
	const [currentForm, setCurrentForm] = useState(() => "reimbursement")
	const [currentStep, setCurrentStep] = useState(() => 0)
	const [onboarding, setOnboarding] = useState(() => {})
	const [insurance, setInsurance] = useState(() => {})
	const [submittedDependents, setSubmittedDependents] = useState(() => {})

	const value = {
		currentForm,
		setCurrentForm,

		currentStep,
		setCurrentStep,

		onboarding,
		setOnboarding,

		insurance,
		setInsurance,

		submittedDependents,
		setSubmittedDependents
	}

	return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>
}
