import { Link } from "react-router-dom"
import { toSalusionPrivate } from "../../util"
import Lottie from "lottie-react"

import animationHero from "../../lottie/salutions-hero.json"

export default function Hero() {
	return (
		<section className="first-section first-padding" id="hero">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-12 col-md-7">
						<div className="hero-content">
							<h1>Lower the Cost of Healthcare</h1>
							<p>Salusion simplifies the administration of tax-advantaged health reimbursement arrangements</p>

							<Link className="btn-linear" onClick={(event) => toSalusionPrivate(event, "/create-account", false)}>
								Start Your Company's HRA Now
							</Link>
						</div>
					</div>
					<div className="col-12 col-md-5">
						<div className="hero-animation">
							<Lottie animationData={animationHero} loop={false} />
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
