import * as Yup from "yup"

export default [
	Yup.object({ insuranceType: Yup.string().required("Insurance Type is required") }),
	Yup.object({}),
	Yup.object({}),
	Yup.object({}),

	Yup.object({
		password: Yup.string()
			.min(8, "Password must be at least 8 characters long")
			.matches(/[A-Z]/, "Password must contain at least one uppercase letter")
			.matches(/[a-z]/, "Password must contain at least one lowercase letter")
			.matches(/[0-9]/, "Password must contain at least one number")
			.matches(/[@$!%*?&#]/, "Password must contain at least one special character (@, $, !, %, etc.)")
			.required("Password is required")
	})
]
