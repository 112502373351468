import { useContext, useState } from "react"

import { OnboardingContext, OnboardingContextProvider } from "./context"
import "./styles.css"

import { OnboardingForm, InsuranceForm, ReimbursementForm } from "./components"
import roadmapFlag from "../../assets/roadmap-flag.svg"
import roadmapArrow from "../../assets/roadmap-wrapper.svg"

function Onboarding() {
	const { currentForm, currentStep } = useContext(OnboardingContext)
	const [displayRoadmap, setDisplayRoadmap] = useState(() => false)

	const forms = {
		onboard: {
			name: "Onboard",
			component: <OnboardingForm />,
			steps: ["ICHRA Eligibility", "ICHRA Participation", "Create Account"]
		},
		insurance: {
			name: "Health Insurance",
			component: <InsuranceForm />,
			steps: ["Insurance Status", "Spouse & Dependents", "Insurance Proof"]
		},
		reimbursement: {
			name: "Reimbursement",
			component: <ReimbursementForm />,
			steps: ["Reimbursement Explanation ", "Reimbursement Information"]
		}
	}

	return (
		<section id="multistep_section">
			<div className="container">
				<div className="form-wrapper">
					<div className={`form-wrapper__nav ${displayRoadmap ? "active" : ""}`}>
						<div className="roadmap-mobile" onClick={() => setDisplayRoadmap(!displayRoadmap)}>
							<div className="roadmap-mobile-label">
								<img src={roadmapFlag} alt="flag" />
								Roadmap
							</div>

							<div className="roadmap-mobile-toggle">
								<img
									src={roadmapArrow}
									alt="arrow"
									style={{ transform: displayRoadmap ? "rotate(180deg)" : "rotate(0deg)" }}
								/>
							</div>
						</div>

						<div className="nav-single__wrapper">
							{Object.keys(forms).map((form, index) => (
								<div key={index} className={`nav-single ${form === currentForm ? "active" : ""}`}>
									<span>{forms[form].name}</span>

									<div className="nav-wrapper">
										{forms[form].steps.map((step, index) => (
											<div key={index} className={index <= currentStep ? "active-step" : ""}>
												{step}
											</div>
										))}
									</div>
								</div>
							))}
						</div>
					</div>

					<div className="form-wrapper__multistep--form">{forms[currentForm].component}</div>
				</div>
			</div>
		</section>
	)
}

export default function OnboardingWithContext() {
	return (
		<OnboardingContextProvider>
			<Onboarding />
		</OnboardingContextProvider>
	)
}
