import { useState } from "react"
import { useFormikContext, Field } from "formik"

export default function CreateAccount() {
	const { handleChange } = useFormikContext()
	const [validation, setValidation] = useState(() => ({}))

	function handlePassword(event) {
		const password = event.target.value

		handleChange(event)
		validatePassword(password)
	}

	function validatePassword(password) {
		setValidation({
			length: password.length >= 8,
			special: /[@$!%*?&#]/.test(password),
			number: /[0-9]/.test(password),
			upperCase: /[A-Z]/.test(password)
		})
	}

	return (
		<>
			<h2>Create Salusion Account</h2>

			<div className="set-password-wrapper">
				<div className="form-group">
					<label htmlFor="password">
						<span>Set Password</span>
						<Field type="password" name="password" placeholder="Enter password" onChange={handlePassword} />
					</label>

					<button type="submit" className="btn-next">
						Save Changes
					</button>

					<ul className="password-validation">
						<li style={{ color: validation.length ? "green" : "#465578" }}>At least 8 characters long</li>
						<li style={{ color: validation.special ? "green" : "#465578" }}>At least one special character</li>
						<li style={{ color: validation.number ? "green" : "#465578" }}>At least one number</li>
						<li style={{ color: validation.upperCase ? "green" : "#465578" }}>At least one uppercase letter</li>
					</ul>
				</div>
			</div>
		</>
	)
}
