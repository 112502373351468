import { useContext } from "react"
import { OnboardingContext } from "../../context"

export default function DependentsTable() {
	const { onboarding, insurance, submittedDependents } = useContext(OnboardingContext)

	return (
		<table className="table-form-all">
			<tbody>
				<tr>
					<td>Type of Insurance</td>
					<td>{onboarding.insuranceType}</td>
				</tr>

				<tr>
					<td>Policy Holder</td>
					<td>{insurance.policyHolder}</td>
				</tr>

				{submittedDependents.map((dependent, index) => (
					<tr key={index}>
						<td>Covered Individuals</td>

						<td>
							{dependent.firstName} {dependent.lastName}, {dependent.relationship}
						</td>
					</tr>
				))}

				<tr>
					<td>Insurance Coverage Period</td>
					<td>January 2025 - December 2025</td>
				</tr>

				<tr>
					<td>Insurance Documents</td>
					<td>{insurance.insuranceProof.name}</td>
				</tr>

				<tr>
					<td>Insurance Carrier</td>
					<td>{insurance.provider}</td>
				</tr>

				<tr>
					<td>Policy ID</td>
					<td>{insurance.policyId}</td>
				</tr>

				<tr>
					<td>Monthly Premium</td>
					<td>${insurance.monthlyPremium}</td>
				</tr>
			</tbody>
		</table>
	)
}
