import { Link } from "react-router-dom"
import "../styles.css"
import { Helmet } from "react-helmet-async"
import ichravsqshera from "../../../assets/ichravsqsehra-poster.svg"
import ichraRules from "../../../assets/ichraRules.svg"
import employees from "../../../assets/50-employees.svg"
import ichraCalculator from "../../../assets/ichra-calculator.svg"
import reimbursement from "../../../assets/reimbursement-options.svg"
import guideIchra from "../../../assets/guide-to-ichra.svg"
import ResourceCenter from "../../../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../../../components/Resource-Center/ResourceCenterVideo"

export const question = "What is the insurance requirement for an ICHRA?"

export default function LearningEntry() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": [
			{
				"@type": "Question",
				"name": "What is the insurance requirement for an ICHRA?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Participation in an ICHRA is limited to employees and dependents who are enrolled in either individual, non-excepted benefit coverage (e.g., coverage consisting solely of vision or dental benefits will not qualify) purchased in the individual market that complies with health care reform's prohibition on lifetime and annual dollar limits (PHSA §2711) and its preventive services mandate (PHSA §2713) or Medicare (Parts A and B or Part C) for each month that they are covered by the ICHRA.",
					"dateCreated": "2024-10-05T14:30:00+00:00",
				},
				"datePublished": "2024-10-05T14:30:00+00:00",
				"dateModified": "2024-10-05T14:30:00+00:00",
			},
		]
	}

	return (
		<>
			<Helmet>
				<title>Salusion - Employer Resources</title>
				<meta name="title" content="FAQ: What is the insurance requirement for an ICHRA?" />
				<meta name="description" content="Participation in an ICHRA is limited to employees and dependents who are enrolled in either individual, non-excepted benefit coverage (e.g., coverage consisting solely of vision or dental benefits will not qualify) purchased in the individual market that complies with health care reform's prohibition on lifetime and annual dollar limits (PHSA §2711) and its preventive services mandate (PHSA §2713) or Medicare (Parts A and B or Part C) for each month that they are covered by the ICHRA." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>

			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							Participation in an ICHRA is limited to employees and dependents who are enrolled in either individual,
							non-excepted benefit coverage (e.g., coverage consisting solely of vision or dental benefits will not
							qualify) purchased in the individual market that complies with health care reform's prohibition on
							lifetime and annual dollar limits (PHSA §2711) and its preventive services mandate (PHSA §2713) or
							Medicare (Parts A and B or Part C) for each month that they are covered by the ICHRA.
						</p>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideIchra}
					title="A flexible, low-cost alternative to group coverage"
					pageLink="/product/ichra/video-guide"
				/>
				<ResourceCenterVideo
					resourceImg={ichraRules}
					title="Take a deep dive into the rules and regulations that govern ICHRAs"
					pageLink="/product/ichra/rules-and-regulations"
				/>

				<ResourceCenterVideo
					resourceImg={ichravsqshera}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>

				<ResourceCenterVideo
					resourceImg={employees}
					title="ICHRA topics for large employers"
					pageLink="/product/ichra/large-business"
				/>

				<ResourceCenterVideo
					resourceImg={ichraCalculator}
					title="Discover how much you can save with an ACA-compliant ICHRA"
					e
					xternalLink="product/ichra/aca-compliant-calculator"
				/>

				<ResourceCenterVideo
					resourceImg={reimbursement}
					title="Discussion of the pros and cons of each reimbursement option"
					pageLink="/product/ichra/reimbursement-options"
				/>
			</ResourceCenter>
		</>
	)
}
