export default function isValid(value) {
	if (value === null) {
		return false
	} else if (value === undefined) {
		return false
	} else if (value === "") {
		return false
	} else if (value === 0) {
		return false
	} else if (Array.isArray(value) && !value.length) {
		return false
	} else if (typeof value === "object" && !(value instanceof Date) && !Object.keys(value).length) {
		return false
	} else {
		return true
	}
}
