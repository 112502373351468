import { Field, ErrorMessage } from "formik"

import { DependentsTable, CheckRecipientTable, BankDetailsTable } from "../../Tables"
import FormButtons from "../../FormButtons"

export default function InsuranceAttest({ step, setStep }) {
	return (
		<>
			<div className="form-content-wrapper">
				<h2>Final Data Check</h2>

				<div className="attestation-wrapper">
					<h3>Insurance Summary</h3>
					<DependentsTable />
					<CheckRecipientTable />
					<BankDetailsTable />
				</div>

				<div className="radio-buttons">
					<label>
						<Field type="checkbox" name="insuranceAttest" />
						<span>I attest that the insurance information entered is accurate.</span>
					</label>

					<ErrorMessage name="insuranceAttest" component="div" className="error-message-fields" />
				</div>
			</div>

			<FormButtons step={step} setStep={setStep} />
		</>
	)
}
