/* eslint-disable jsx-a11y/anchor-is-valid */
import FormButtons from "../../FormButtons"

import { InsuranceStatus, DependentDetails } from "."
import { InsuranceProof, InsuranceDetails, Attestation } from "."

export default function Dependents(props) {
	const { step, setStep, setSteps, dependents } = props
	const { updateDependent, deleteDependent } = props

	function addDependent(event) {
		event.preventDefault()

		setStep(step + 1)
		setSteps([InsuranceStatus, Dependents, DependentDetails])
	}

	function handleNextStep() {
		setSteps([InsuranceStatus, Dependents, InsuranceProof, InsuranceDetails, Attestation])
	}

	return (
		<>
			<div className="form-content-wrapper">
				<h2>Add Spouse and Dependents</h2>
				<h3>Who else is covered by your insurance?</h3>

				<div className="dependents-wrapper">
					{dependents.map((dependent, index) => (
						<div key={index} className="dependents-entry">
							<div className="dependents-label">
								<p>
									{dependent.firstName} {dependent.lastName}, {dependent.relationship}
								</p>
							</div>

							<div className="dependents-links">
								<button onClick={() => updateDependent(dependent)}>Update</button>
								<button onClick={() => deleteDependent(dependent.id)}>Delete</button>
							</div>
						</div>
					))}
				</div>

				<a className="dependents-add" onClick={addDependent}>
					+ Add Spouse or Dependent
				</a>
			</div>

			<FormButtons step={step} setStep={setStep} onNextStep={handleNextStep} />
		</>
	)
}
