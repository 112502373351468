/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react"
import { Field } from "formik"

export default function DependentDetails(props) {
	const { setStep, updatedDependent, setUpdatedDependent, addDependent } = props
	const [dependent, setDependent] = useState(() => getInitialValues())

	function handleChange(event) {
		const { name, value } = event.target
		setDependent({ ...dependent, [name]: value })
	}

	function handleSubmit(event) {
		event.preventDefault()

		const _dependent = structuredClone(dependent)
		_dependent.id = updatedDependent ? updatedDependent.id : Date.now()

		setStep(1)
		addDependent(_dependent)
		setDependent(getInitialValues())
	}

	function handleBack(event) {
		event.preventDefault()

		setStep(1)
		setUpdatedDependent(null)
	}

	useEffect(() => {
		if (updatedDependent) setDependent(updatedDependent)
	}, [updatedDependent])

	return (
		<>
			<h2>Spouse & Dependent Submission</h2>

			<div className="d-flex">
				<div className="form-group d-flex-50">
					<label>
						<span>First Name</span>

						<Field
							type="text"
							name="firstName"
							placeholder="Katherine"
							onChange={handleChange}
							value={dependent.firstName}
						/>
					</label>
				</div>

				<div className="form-group d-flex-50">
					<label>
						<span>Last Name</span>

						<Field
							type="text"
							name="lastName"
							placeholder="Grettum"
							value={dependent.lastName}
							onChange={handleChange}
						/>
					</label>
				</div>

				<div className="form-group d-flex-50">
					<label>
						<span>Relationship</span>

						<Field as="select" name="relationship" value={dependent.relationship} onChange={handleChange}>
							<option value="spouse">Spouse</option>
							<option value="dependent">Dependent</option>
						</Field>
					</label>
				</div>

				<div className="form-group d-flex-50">
					<label>
						<span>Date of Birth</span>

						<Field type="date" name="dob" placeholder="01/01/1990" onChange={handleChange} value={dependent.dob} />
					</label>
				</div>

				<div className="form-group d-flex-50">
					<label>
						<span>Gender</span>

						<Field as="select" name="gender" value={dependent.gender} onChange={handleChange}>
							<option value="male">Male</option>
							<option value="female">Female</option>
						</Field>
					</label>
				</div>

				<div className="form-group d-flex-50">
					<label>
						<span>Street Address</span>

						<Field
							type="text"
							name="addressLine1"
							placeholder="Street Address"
							value={dependent.addressLine1}
							onChange={handleChange}
						/>
					</label>
				</div>

				<div className="form-group d-flex-50">
					<label>
						<span>Apt, etc. (Optional)</span>
						<Field
							type="text"
							name="addressLine2"
							value={dependent.addressLine2}
							placeholder="Apt, etc. (optional)"
							onChange={handleChange}
						/>
					</label>
				</div>

				<div className="form-group d-flex-50">
					<label>
						<span>City</span>
						<Field type="text" value={dependent.city} name="city" placeholder="City" onChange={handleChange} />
					</label>
				</div>

				<div className="form-group d-flex-50">
					<label>
						<span>State</span>
						<Field type="text" value={dependent.state} name="state" placeholder="State" onChange={handleChange} />
					</label>
				</div>

				<div className="form-group d-flex-50">
					<label>
						<span>Zip Code</span>
						<Field type="number" value={dependent.zip} name="zip" placeholder="Zip Code" onChange={handleChange} />
					</label>
				</div>
			</div>

			<div className="btn-wrapper-form">
				<a href="#" onClick={handleBack} className="btn-back">
					Back
				</a>

				<a href="#" className="btn-next" onClick={handleSubmit}>
					Save
				</a>
			</div>
		</>
	)
}

function getInitialValues() {
	return {
		firstName: "",
		lastName: "",
		relationship: "",
		dob: "",
		gender: "",
		addressLine1: "",
		addressLine2: "",
		city: "",
		state: "",
		zip: ""
	}
}
