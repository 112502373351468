import FormButtons from "../../FormButtons"

export default function Marketplace({ step, setStep }) {
	return (
		<>
			<div className="form-content-wrapper">
				<h2>Marketplace</h2>
				<h3>We recommend getting your insurance from off the marketplace.</h3>

				<div className="marketplace-wrapper-all">
					<h3>Off-Marketplace Broker</h3>
				</div>
			</div>

			<FormButtons step={step} setStep={setStep} hideNext={true} />
		</>
	)
}
