import { useRef, useState } from "react"

import uploadIcon from "../../assets/uploadIcon.svg"
import EmployerTable from "./EmployerTable"

export default function EmployerUpload() {
	const inputRef = useRef()
	const [uploadName, setUploadName] = useState("Upload File")
	const [table, setTable] = useState(false)

	function handleFileUpload(e) {
		const files = e.target.files
		if (!files) return

		const file = files[0]

		setUploadName(file.name)

		if (uploadName !== "Upload file") {
			setTable(true)
		}
	}

	function handleButtonClick(e) {
		e.preventDefault()
		if (!inputRef || !inputRef.current) return

		inputRef.current.click()
	}

	return (
		<>
			<section id="upload_section">
				<div className="container">
					<div className="upload-wrapper">
						<form className="upload-form">
							<button onClick={handleButtonClick}>
								<img src={uploadIcon} alt="upload employees" />
								{uploadName}
							</button>
							<input ref={inputRef} type="file" hidden onChange={handleFileUpload} />
						</form>
						<div className="download-btn-wrapper">
							<a href="#" download className="dowload-btn">
								Download File
							</a>
						</div>
					</div>
					{table && <EmployerTable />}
				</div>
			</section>
		</>
	)
}
