import { Link } from "react-router-dom"
import { toSalusionPrivate } from "../../util"

export default function ResourceCenterVideo({ resourceImg, title, pageLink, externalLink }) {
	function goToExternalLink(event) {
		if (!externalLink) return
		else toSalusionPrivate(event, externalLink)
	}

	return (
		<div className="col-12 col-md-6">
			<Link to={pageLink} onClick={goToExternalLink}>
				<div className="video-single">
					<div className="video-single__wrapper">
						<img src={resourceImg} alt="hra video" />
					</div>
					<h3>{title}</h3>
				</div>
			</Link>
		</div>
	)
}
