import { Link } from "react-router-dom"
import "../styles.css"
import { Helmet } from "react-helmet-async"
import guideEbhra from "../../../assets/guide-ebhra.svg"
import ResourceCenter from "../../../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../../../components/Resource-Center/ResourceCenterVideo"

export const question = "What is an EBHRA?"

export default function LearningEntry() {
    const schema = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
            {
                "@type": "Question",
                "name": "What is an EBHRA?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "An Excepted Benefit HRA (EBHRA) is a limited-dollar health reimbursement arrangement offered by employers. The maximum amount an employer can offer is indexed yearly ($2,150 for 2025).",
                    "dateCreated": "2024-10-05T14:30:00+00:00",
                },
                "datePublished": "2024-10-05T14:30:00+00:00",
                "dateModified": "2024-10-05T14:30:00+00:00",
            },
        ]
    }

    return (
        <>
            <Helmet>
                <title>Salusion - Employer Resources</title>
                <meta name="title" content="FAQ: What is an EBHRA?" />
                <meta name="description" content="An Excepted Benefit HRA (EBHRA) is a limited-dollar health reimbursement arrangement offered by employers." />
                <script type="application/ld+json">{JSON.stringify(schema)}</script>
            </Helmet>

            <section className="first-section-full-background first-padding rules-all">
                <div className="container">
                    <h1>Learning Center</h1>

                    <div className="entry-container white-wrapper-all">
                        <h3>{question}</h3>
                        <hr className="divider" />
                        <p className="text">An Excepted Benefit HRA (EBHRA) is a limited-dollar health reimbursement arrangement offered by employers. The maximum amount an employer can offer is indexed yearly ($2,150 for 2025).</p>
                        <p className="text">It allows reimbursement for <a href="https://www.law.cornell.edu/uscode/text/26/213">Code §213(d)</a> out-of-pocket medical expenses, but cannot be used for premiums for individual health coverage, Medicare, or non-COBRA group coverage. Employers can limit the scope of eligible expenses.</p>
                        <p className="text">To participate in an EBHRA, employees must be offered a traditional group health plan by their employer. However, unlike other HRAs, employees do not have to enroll in the group health plan to use the EBHRA. The employer can limit eligibility to certain employee groups, but the EBHRA must be made available on the same terms to all similarly situated employees, as defined by factors like job classification, full-time/part-time status, or geographic location, in accordance with nondiscrimination rules.</p>
                        <Link to="/learning-center" className="link">
                            <i className="back-icon bi bi-arrow-left" />
                            Back To Learning Center
                        </Link>
                    </div>
                </div>
            </section>
            <ResourceCenter>
                <ResourceCenterVideo
                    resourceImg={guideEbhra}
                    title="Simple, Affordable EBHRAs"
                    pageLink="/product/ebhra"
                />

            </ResourceCenter>

        </>
    )
}
