import React, { useContext, useState, useEffect } from "react"
import { Formik, Form } from "formik"

import { OnboardingContext } from "../../context"
import initialValues from "./initialValues"
import validations from "./validations"

import { PaymentOption, BankAccount, InsuranceAttest } from "./steps"
import { PayrollAttest, PremiumStartDate, CheckRecipient, CheckAddress } from "./steps"

export default function ReimbursementForm() {
	const { setCurrentStep } = useContext(OnboardingContext)

	const [step, setStep] = useState(() => 0)
	const [steps, setSteps] = useState(() => [PaymentOption])
	const [validation, setValidation] = useState(() => validations.default)

	function handleSubmit(reimbursement) {
		const paymentOption = reimbursement.paymentOption
		const lastStep = steps.length - 1

		if (step === 0) {
			setNextSteps(paymentOption)
			setStep(step + 1)
		} else if (step < lastStep) {
			setStep(step + 1)
		} else if (step === lastStep) {
			console.log("reimbursement form complete", reimbursement)
		}
	}

	function setNextSteps(paymentOption) {
		if (paymentOption === "ACH") {
			setValidation(validations.ach)
			setSteps([PaymentOption, BankAccount, InsuranceAttest])
		} else {
			setValidation(validations.direct)
			setSteps([PaymentOption, PayrollAttest, PremiumStartDate, CheckRecipient, CheckAddress, InsuranceAttest])
		}
	}

	useEffect(() => {
		const currentStep = steps[step]

		if (currentStep === PaymentOption) {
			setCurrentStep(0)
		} else if (currentStep === PayrollAttest) {
			setCurrentStep(0)
		} else if (currentStep === PremiumStartDate) {
			setCurrentStep(1)
		}
	}, [setCurrentStep, step, steps])

	return (
		<Formik initialValues={initialValues} validationSchema={validation[step]} onSubmit={handleSubmit}>
			<Form>
				{React.createElement(steps[step], {
					step,
					setStep,

					steps,
					setSteps
				})}
			</Form>
		</Formik>
	)
}
