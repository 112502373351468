import { Helmet } from "react-helmet-async"
import { Stack } from "react-bootstrap"
import Calendly from "../components/Calendly"

export default function SpeakWithExpert() {
	return (
		<>
			<Helmet>
				<title>Salusion - Speak With An Expert</title>
			</Helmet>

			<section id="contact" className="first-section first-padding">
				<div className="container">
					<Stack className="hra-contact-wrapper white-wrapper-all">
						<h2>Speak with an Expert</h2>
						<Calendly />
					</Stack>
				</div>
			</section>
		</>
	)
}
