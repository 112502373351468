import Accordion from "react-bootstrap/Accordion"

export default function IchraAccordion() {
	return (
		<Accordion>
			<Accordion.Item eventKey="0">
				<Accordion.Header>Individuals Who Can Participate in an ICHRA</Accordion.Header>
				<Accordion.Body>
					<h4>Insurance Requirement</h4>
					<p>Employees are eligible to participate in an ICHRA if they are enrolled in one of the following health plans for each month they receive benefits from the ICHRA:
						<ul>
							<li>
								Individual Market Health Insurance: This includes any health insurance purchased in the individual market that complies with health care reform rules, such as prohibitions on lifetime and annual dollar limits (PHSA §2711) and the preventive services mandate (PHSA §2713).
							</li>
							<li>
								Medicare: This includes coverage under Medicare Parts A and B, or Part C.
							</li>
						</ul>
					</p>
					<h4>Owners Are Not Employees</h4>
					<p>The following individuals do not qualify as employees and, therefore, cannot participate in an ICHRA:
						<ul>
							<li>Sole Proprietors</li>
							<li>Partners</li>
							<li>
								More-than-2% S Corporation Shareholders: This exclusion also applies to the children, dependents, parents, and grandparents of more-than-2% shareholders
							</li>
							<li>Non-employee Independent Contractors</li>
							<li>Non-employee Owners: Employee owners of C corporations and LLCs are eligible to participate</li>
						</ul>
					</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="1">
				<Accordion.Header>ICHRA Classes</Accordion.Header>
				<Accordion.Body>
					<h4>ICHRAs Are Offered to Classes of Employees</h4>
					<p>
						To offer an ICHRA, employers must create distinct, non-overlapping classes of employees from one or more of the following permitted categories:
						<ul>
							<li>Full-Time Employees</li>
							<li>Part-Time Employees</li>
							<li>Salaried Employees</li>
							<li>Non-Salaried Employees (e.g., hourly workers)</li>
							<li>Employees Whose Primary Site of Employment is in the Same Rating Area</li>
							<li>Seasonal Employees</li>
							<li>
								Employees Covered by a Collective Bargaining Agreement
							</li>
							<li>
								Employees in a Waiting Period
							</li>
							<li>Nonresident Aliens with No U.S.-Based Income</li>
							<li>Temporary Employees Placed at an Unrelated Entity</li>
						</ul>
					</p>
					<h4>
						Uniform Terms Within Employee Classes
					</h4>
					<p>
						An ICHRA must be offered under the same terms and conditions to all employees within a class. However, the benefit amount can vary based on:
						<ul>
							<li>
								<b>Age</b>: The benefit may increase with age, up to three times the amount available to the youngest participant.
							</li>
							<li>
								<b>Family Size</b>: The benefit may adjust according to family size, based on the number of dependents.
							</li>
						</ul>
					</p>
					<h4>Classes Determined at Common-Law Employer Level</h4>
					<p>
						Employee classes are determined at the common-law employer level, allowing each employer within a related group of companies to create its own classifications and benefits independently.
					</p>
					<h4>Cannot Offer Group Health and an ICHRA to the Same Class of Employees</h4>
					<p>
						Employers cannot offer both a traditional group health plan and an ICHRA to the same class of employees. However, there is a special provision for new hires. This allows employers to offer a traditional plan to existing employees while offering an ICHRA to new employees hired after a specified future date.
					</p>
					<h4>Offering Group Health Plans Invokes Minimum Class Sizes Requirements</h4>
					<p>
						If an ICHRA sponsor offers a group health plan to any class of employees while offering an ICHRA to one or more other classes, a minimum class size requirement applies to certain classes of employees receiving an ICHRA. This requirement does not apply to classes offered a traditional health plan or no coverage at all.
					</p>
					<p>The following classes are subject to the minimum class size requirement if they are offered an ICHRA:
						<ul>
							<li>Full-Time Employees: If part-time employees are offered a traditional health plan.</li>
							<li>Part-Time Employees: If full-time employees are offered a traditional health plan.</li>
							<li>Salaried Employees</li>
							<li>Non-Salaried Employees</li>
							<li>
								Employees Whose Primary Site of Employment is in the Same Rating Area: Unless the rating area is a state or a combination of two or more entire states.
							</li>
							<li>
								Combined Classes: A class created by combining any of the above with another class.
							</li>
						</ul>
					</p>
					<p>
						However, this requirement does not apply if the class combines one of the above categories with employees who have not yet satisfied a waiting period for coverage. Additionally, if the special rule for new hires is applied, the minimum class size requirement will not apply to the new hire subclass unless it is later subdivided, in which case the requirement will apply if it would otherwise be applicable.
					</p>
					<p>
						For a class of employees subject to the minimum class size requirement, the class must include a minimum number of employees for the ICHRA sponsor to treat it as a separate class. The minimum number of employees required depends on the size of the employer:
						<ul>
							<li>10 Employees: For an employer with fewer than 100 employees.</li>
							<li>
								10% of Total Employees: (Rounded down to a whole number) For an employer with 100 to 200 employees.
							</li>
							<li>20 Employees: For an employer with more than 200 employees.
							</li>
						</ul>
					</p>
					<p>
						The applicable number is determined before the start of each plan year based on the number of employees the employer reasonably expects to employ on the first day of the plan year. Whether a class meets the minimum required number is based on the number of employees offered the ICHRA as of the first day of the plan year, not the number who actually enroll in the ICHRA. Changes in the number of employees in the class during the plan year do not affect this determination.
					</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="2">
				<Accordion.Header>Eligible Expenses</Accordion.Header>
				<Accordion.Body>
					<h4>What</h4>
					<p>ICHRAs may reimburse only medical expenses defined in <a href="https://www.law.cornell.edu/uscode/text/26/213">Code §213(d)</a>:
						<ul>
							<li>
								The primary definition for “medical care” under Code §213(d), is amounts paid “for the diagnosis, cure, mitigation, treatment, or prevention of disease, or for the purpose of affecting any structure or function of the body.”  In simpler terms, most out-of-pocket medical expenses are eligible for reimbursement, including transportation and lodging associated with the medical expenses.
							</li>
							<li>Code §213(d) also includes insurance premiums. These are reimbursable under an ICHRA.</li>
							<li>
								The <a href="https://www.congress.gov/bill/116th-congress/senate-bill/3548/text">CARES Act</a>, in 2020, further expanded medical care to include over-the-counter medicines (cold and flu, sunblock, etc...) and menstrual products.
							</li>
							<li>Procedures that are cosmetic or illegal are not eligible.
							</li>
						</ul>
					</p>
					<p>
						Employers choose which of the Code §213(d) expenses are reimbursable. Employers typically choose to cover all of Section 213(d) expenses (insurance premiums and out-of-pocket medical expenses) or insurance premiums only.
					</p>
					<h4>Who</h4>
					<p>
						The medical expense must be incurred by the employee, the employee's spouse, an employee's child who has not attained age 27 as of the end of the employee's taxable year, or the employee's tax dependent for health coverage purposes.
					</p>
					<h4>When</h4>
					<p>
						ICHRAs can only reimburse expenses that are incurred while the employee was an eligible employee during the plan year.
					</p>
					<h4>Substantiation</h4>
					<p>For a reimbursement to be tax-free, health claims must be substantiated with two items:
						<ul>
							<li>
								Information from an independent third party describing the service or product, the date of the service or sale, and the amount of the expense; and
							</li>
							<li>
								A statement from the participant providing that the medical expense has not been reimbursed and that the participant will not seek reimbursement for the expense under any other health plan coverage
							</li>
						</ul>
					</p>
					<p>Salusion captures and verifies employee health expenses.</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="3">
				<Accordion.Header>Benefits Compatible With an ICHRA</Accordion.Header>
				<Accordion.Body>
					<h4>Employers Can Offer Excepted Benefits</h4>
					<p>
						ICHRA sponsors may offer both an ICHRA and one or more excepted benefits (other than an excepted benefit HRA), such as vision or dental coverage, or a health FSA.
					</p>
					<h4>Premium-Only ICHRAs Are HSA-Compatible</h4>
					<p>
						ICHRAs can be designed to be HSA-compatible by only reimbursing premiums or limiting reimbursements in accordance with HSA rules.
					</p>
					<h4>Off-Exchange Premiums Can Be Passed Through a Cafeteria Plan</h4>
					<p>
						Employers may allow employees to use pre-tax cafeteria plan salary reductions to pay the portion of the premiums for individual medical coverage not covered by the ICHRA, as long as the coverage is purchased outside of an Exchange.
					</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="4">
				<Accordion.Header>Premium Tax Credits & Opting Out</Accordion.Header>
				<Accordion.Body>
					<h4>Premium Tax Credits</h4>
					<p>
						A premium tax credit (PTC) provides financial assistance to pay the premiums of a qualified health plan offered through a Marketplace by reducing the amount of tax you owe, giving you a refund, or increasing your refund amount.  Typically PTCs are advanced and paid directly to the insurance provider based on the Marketplace’s estimate of the PTC that can be taken on your tax return.  PTCs and the reconciliation of the advances are made on IRS Form 8962.
					</p>
					<h4>Affordability, Opting In/Out, and PTCs</h4>
					<p>
						If the self-only ICHRA allowance is greater than the difference between the cost of the lowest cost silver plan on the marketplace (LCS) and 9.02% of the employee’s household income, then the ICHRA is considered “affordable”.
						<ul>
							<li>If an employee is offered an affordable ICHRA, then they cannot claim PTCs.</li>
							<li>If an employee is offered an ICHRA and they opt into it, then they cannot claim PTCs.</li>
							<li>
								If an employee opts out an ICHRA and the ICHRA is considered unaffordable, then they may claim the premium tax credit.
							</li>
						</ul>
					</p>
					<h4>Employees Are Permitted to Opt Out</h4>
					<p>
						Employees who are eligible for coverage under an ICHRA must be permitted to opt out of and waive future reimbursements from the ICHRA (on behalf of themselves and all eligible dependents) with respect to each plan year.
					</p>
					<p>
						The HRA may establish timeframes for enrollment in (and opting out of) the HRA, but an opt-out opportunity must be provided in advance of the first day of the plan year. For participants or dependents who become eligible midyear, or participants who become eligible fewer than 90 days prior to the plan year, this opportunity must be provided during the applicable ICHRA enrollment period. Upon termination of employment, ICHRA participants must either forfeit the remaining balance (subject to COBRA) or be permitted to permanently opt out of and waive future reimbursements on behalf of themselves and all covered dependents.
					</p>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="5">
				<Accordion.Header>Notice Requirements</Accordion.Header>
				<Accordion.Body>
					<h4>Notice Timing</h4>
					<p>
						A notice relating to the ICHRA must be provided at least 90 days before the beginning of each plan year or no later than the date an employee is first eligible to participate in the ICHRA, if the employee is not eligible to participate at the beginning of the plan year or when the notice is provided to other participants.
					</p>
					<p>
						The notice for the first plan year can be provided no later than the date on which the ICHRA is first effective for the participant.
					</p>
					<h4>Notice Content</h4>
					<p>
						Salusion uses the <a href="https://www.dol.gov/sites/dolgov/files/ebsa/laws-and-regulations/rules-and-regulations/completed-rulemaking/1210-AB87/individual-coverage-model-notice.pdf">model notice</a> recommended by the Departments of the Treasury, Labor, and Health and Human Services.
					</p>
				</Accordion.Body>
			</Accordion.Item>

			<Accordion.Item eventKey="6">
				<Accordion.Header>Reporting Requirements</Accordion.Header>
				<Accordion.Body>
					<h4>Small Employers: 1094-B & 1095-B</h4>
					<p>
						ICHRA providers not subject to the employer shared responsibility provisions file Forms 1094-B and 1095-B to report information about individuals covered by minimum essential health coverage.
						<ul>
							<li>
								Form 1095-B is provided to individuals covered by minimum essential health coverage. Offering an ICHRA to an employee is considered offering minimum essential coverage.
							</li>
							<li>
								Form 1094-B serves as a transmittal form, summarizing and transmitting Forms 1095-B to the IRS. It includes details about the entity providing health coverage, such as the employer or insurance provider.
							</li>
						</ul>
					</p>
					<h4>ALEs: 1094-C & 1095-C</h4>
					<ul>
						<li>
							Employers with 50 or more full-time employees in the previous year use Forms 1094-C and 1095-C to report information about health coverage offers and enrollment for employees.
						</li>
						<li>
							Form 1094-C helps determine whether an ALE Member owes a payment under the employer shared responsibility provisions (section 4980H).
						</li>
						<li>Form 1095-C is also used to assess the eligibility of employees for the premium tax credit.</li>
					</ul>
					<h4>Filing Deadlines</h4>
					<p>
						Forms 1094 and 1095 must be filed with the IRS by February 28 (paper filing) or March 31 (electronic filing) of the year following the calendar year of coverage.
					</p>
					<h4>Special Enrollment Periods</h4>
					<p>
						Employers that offer a new ICHRA to employees prompt an individual market special enrollment period, which
						gives employees and their dependents 60 days to enroll in individual insurance coverage or change from one
						individual coverage plan to another.
					</p>
				</Accordion.Body>
			</Accordion.Item>

			<Accordion.Item eventKey="7">
				<Accordion.Header>Special Enrollment Periods</Accordion.Header>
				<Accordion.Body>
					<p>
						Employers that offer a new ICHRA to employees prompt an individual market special enrollment period, which gives employees and their dependents 60 days to enroll in individual insurance coverage or change from one individual coverage plan to another.
					</p>
				</Accordion.Body>
			</Accordion.Item>

			<Accordion.Item eventKey="8">
				<Accordion.Header>Mechanics of a Salusion ICHRA</Accordion.Header>
				<Accordion.Body>
					<h4>Plan Year</h4>
					<p>
						A plan year describes the 12-month period that an ICHRA is in effect.  An ICHRA may be started in any month of the year.  If an ICHRA is started on any date after January 1st, then the initial plan year  is a "short plan year" ending on December 31st and subsequent plan years will be aligned with the calendar year.
					</p>
					<h4>Subsequent Plan Years</h4>
					<p>
						Salusion’s ICHRA automatically rolls from one plan year to the next.  105 days before the start of the subsequent plan year, Salusion will make the subsequent plan year available to amend. 90 days before the start of the subsequent plan year, Salusion will send the required notices.
					</p>
					<h4>Annual Allowance</h4>
					<p>
						Employers define the annual allowance, which is the maximum amount that can be reimbursed under the ICHRA in a plan year.  There is no cap on the annual allowance.
					</p>
					<h4>Annual Allowance by Family Size & Age</h4>
					<p>
						For each class of employees offered an ICHRA, the employer can increase the annual allowance available based on age (by up to three times the annual allowance available to the youngest participant) or family size, or both factors. The age and family size determinations are made as of the first day of the ICHRA plan year and will not change if the employee's circumstances change during the year (e.g., the employee marries or divorces).
					</p>
					<h4>Annual Allowance Availability</h4>
					<p>
						Salusion’s ICHRA makes the annual allowance available, pro rata, on a monthly basis.
					</p>
					<p>
						(eg. The annual allowance is $3,600.  On the first day of the plan year, $300 is available for reimbursements.
						<ul>
							<li>
								Suppose on the first day of the plan year, an employee submits an expense for $500.  Salusion would process a $300 reimbursement immediately.  The remaining $200 reimbursement would be processed on the first day of the following month when additional allowance is made available.
							</li>
							<li>
								Suppose the employee did not submit a claim in the first month of the plan year. On the first day of the following month $600 is available for reimbursement.)
							</li>
						</ul>
					</p>
					<h4>Year End Runout</h4>
					<p>
						The year end runout is the period of time after a plan year ends that employees can submit expenses for that plan year.  The year end runout on Salusion’s ICHRA is 75 days.
					</p>
					<h4>Termination Runout</h4>
					<p>
						The termination runout is the period of time after the employee is terminated that the employees can submit expenses for that plan year.  The termination runout on Salusion’s ICHRA is 0 days.
					</p>
					<h4>Carryover Amounts</h4>
					<p>
						ICHRAs allow unused allowances to roll over from one plan year to the next. To avoid accumulating uncapped liabilities over time, Salusion disables carryover amounts.
					</p>
				</Accordion.Body>
			</Accordion.Item>

		</Accordion>
	)
}
