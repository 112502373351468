import { getLearningEntryRoutes } from "../../util"

import * as ICHRA from "./ICHRA"
import * as QSEHRA from "./QSEHRA"
import * as OTHER from "./OTHER"

export const ichraRoutes = getLearningEntryRoutes(ICHRA)
export const qsehraRoutes = getLearningEntryRoutes(QSEHRA)
export const otherRoutes = getLearningEntryRoutes(OTHER)

export default [...ichraRoutes, ...qsehraRoutes, ...otherRoutes]
