import FormButtons from "../../FormButtons"
import { InsuranceType, Affordability } from "."
import { Agreement, CreateAccount } from "."

export default function Participation({ step, setStep, setSteps }) {
	function handleNextStep() {
		setSteps([InsuranceType, Participation, Agreement, CreateAccount])
		setStep(step + 1)
	}

	function goToAffordability() {
		setSteps([InsuranceType, Participation, Affordability, Agreement, CreateAccount])
		setStep(step + 1)
	}

	return (
		<>
			<div className="form-content-wrapper">
				<h2>ICHRA Participation</h2>

				<h3>
					If you qualify or think you might qualify for premium tax credits on individual coverage insurance purchased
					through the marketplace, please read this section carefully.
				</h3>

				<div className="list-wrapper">
					<ul>
						<h4>If your ICHRA is considered "Affordable":</h4>
						<li>You and your family will not receive premium tax credits</li>

						<h4>If you accept the ICHRA</h4>
						<li>You and your family will not receive premium tax credits.</li>

						<h4>If your ICHRA is considered "Unaffordable":</h4>
						<li>You can decline the ICHRA and maintain eligibility to receive premium tax credits</li>
					</ul>
				</div>
			</div>

			<FormButtons step={step} setStep={setStep} onNextStep={handleNextStep}>
				<button type="submit" className="check-affordability" onClick={goToAffordability}>
					Check Affordability
				</button>
			</FormButtons>
		</>
	)
}
