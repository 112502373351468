import BenefitsEmployerNumber from "./BenefitsEmployerNumber"
import BenefitsEmployeeNumber from "./BenefitsEmployeeNumber"
export default function Benefits() {
	return (
		<section id="benefits">
			<div className="container">
				<div className="benefits-wrapper">
					<div className="row align-items-center">
						<div className="col-12 col-md-6">
							<div className="benefits-content">
								<h2>Your Partner in Benefits</h2>
								<p>With 1,100 employers and 19,000 employees, Salusion is a trusted choice for administering HRAs</p>
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="row">
								<BenefitsEmployerNumber number="1,100" />
								<BenefitsEmployeeNumber number="19,000" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
