import * as Yup from "yup"

export default {
	default: [Yup.object({})],

	ach: [
		Yup.object({}),

		Yup.object({
			bankName: Yup.string().required("Bank Name is required"),
			nickname: Yup.string().required("Nickname is required"),
			routingNumber: Yup.string().required("Routing Number is required"),
			accountNumber: Yup.string().required("Account Number is required"),
			accountType: Yup.string().required("Account Type is required")
		}),

		Yup.object({
			insuranceAttest: Yup.boolean()
				.oneOf([true], "Attestation is required")
				.required("You must confirm the attestation.")
		})
	],

	direct: [
		Yup.object({}),

		Yup.object({
			payrollAttest: Yup.boolean()
				.oneOf([true], "Attestation is required")
				.required("You must confirm the attestation.")
		}),

		Yup.object({}),
		Yup.object({}),

		Yup.object({
			providerCheckRecipient: Yup.string().required("Check Recipient is required"),
			providerAddress1: Yup.string().required("Address is required"),
			providerAddress2: Yup.string(),
			providerCity: Yup.string().required("City is required"),
			providerState: Yup.string().required("State is required"),
			providerZip: Yup.string().required("Zip is required")
		}),

		Yup.object({
			insuranceAttest: Yup.boolean()
				.oneOf([true], "Attestation is required")
				.required("You must confirm the attestation.")
		})
	]
}
