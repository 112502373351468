import "../components/Rules/Rules.css"

import IchraAccordion from "../components/Rules/IchraAccordion"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"

import file from "../assets/documents/ICHRA Rules & Regs.pdf"
import ichravsqshera from "../assets/ichravsqsehra-poster.svg"
import employees from "../assets/50-employees.svg"
import ichraCalculator from "../assets/ichra-calculator.svg"
import guideIchra from "../assets/guide-to-ichra.svg"
import reimbursement from "../assets/reimbursement-options.svg"
import { Helmet } from "react-helmet-async"

export default function IchraRules() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "Article",
		"headline": "ICHRA Rules & Regulations",
		"author": {
			"@type": "Organization",
			"name": "Salusion"
		},
		"datePublished": "2024-10-05T14:30:00+00:00",
		"dateModified": "2024-10-05T14:30:00+00:00",
		"articleBody": "This article explains the rules and regulations governing Individual Coverage Health Reimbursement Arrangements (ICHRA)."
	}

	return (
		<>
			<Helmet>
				<title>Salusion - ICHRA Rules</title>
				<meta name="title" content="ICHRA Rules & Regulations | Learn ICHRA Compliance | Salusion" />
				<meta name="description" content="Discover everything you need to know about ICHRA rules and regulations.  Learn how to offer tax-free health reimbursements, structure employee classes, and meet reporting requirements for hassle-free ICHRA administration." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>

			<section id="ichra_rules" className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>ICHRA Rules & Regulations</h1>

					<div className="white-wrapper-all gsehra-rules-reg-wrapper">
						<h3>
							An Individual Coverage Health Reimbursement Arrangement (ICHRA) allows employers to reimburse employees
							for medical expenses up to a set annual allowance. These reimbursements are typically tax-free for both
							federal income and employment taxes.
						</h3>

						<a href={file} className="btn-linear" download="ICHRA Rules & Regs.pdf">
							Download PDF
						</a>

						<div className="guide-accordion-all">
							<IchraAccordion />
						</div>
					</div>
				</div>
			</section>

			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideIchra}
					title="A flexible, low-cost alternative to group coverage"
					pageLink="/product/ichra/video-guide"
				/>

				<ResourceCenterVideo
					resourceImg={ichravsqshera}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>

				<ResourceCenterVideo
					resourceImg={employees}
					title="ICHRA topics for large employers"
					pageLink="/product/ichra/large-business"
				/>

				<ResourceCenterVideo
					resourceImg={ichraCalculator}
					title="Discover how much you can save with an ACA-compliant ICHRA"
					e
					xternalLink="product/ichra/aca-compliant-calculator"
				/>

				<ResourceCenterVideo
					resourceImg={reimbursement}
					title="Discussion of the pros and cons of each reimbursement option"
					pageLink="/product/ichra/reimbursement-options"
				/>


			</ResourceCenter>
		</>
	)
}
