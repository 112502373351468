import ResourceCenterVideo from "./ResourceCenterVideo"
import video1 from "../../videos/products.mp4"

export default function ResourceCenter({ children }) {
	return (
		<section id="resource_center">
			<div className="container">
				<div className="resource-center__wrapper">
					<h2>Employer Resources</h2>
					<div className="row">{children}</div>
				</div>
			</div>
		</section>
	)
}
