import { Field, ErrorMessage } from "formik"
import FormButtons from "../../FormButtons"
import { DependentsTable } from "../../Tables"

export default function Attestation({ step, stepStep, dependents }) {
	return (
		<>
			<div className="form-content-wrapper">
				<h2>Attestation</h2>

				<div className="attestation-wrapper">
					<h3>Insurance Summary</h3>
					<DependentsTable />

					<div className="radio-buttons">
						<label>
							<Field type="checkbox" name="insuranceAttest" />
							<span>I attest that the insurance information entered is accurate.</span>
						</label>

						<ErrorMessage name="insuranceAttest" component="div" className="error-message-fields" />
					</div>
				</div>
			</div>

			<FormButtons step={step} setStep={stepStep} nextLabel="Save" />
		</>
	)
}
