import { Link } from "react-router-dom"
import "../styles.css"
import { Helmet } from "react-helmet-async"
import guideQsehra from "../../../assets/guide-salusions.svg"
import ichraVsQsehra from "../../../assets/ichravsqsehra-poster.svg"
import qsehraRules from "../../../assets/qsehra-rules.svg"
import ResourceCenter from "../../../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../../../components/Resource-Center/ResourceCenterVideo"

export const question = "How does a QSEHRA allowance work?"

export default function LearningEntry() {
    const schema = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
            {
                "@type": "Question",
                "name": "How does a QSEHRA allowance work?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "a QSEHRA reimburses eligible expenses, up to a maximum annual amount, per plan year. Typically, your allowance is allocated in monthly increments, and unused allowance accrues throughout the plan year.",
                    "dateCreated": "2024-10-05T14:30:00+00:00",
                },
                "datePublished": "2024-10-05T14:30:00+00:00",
                "dateModified": "2024-10-05T14:30:00+00:00",
            },
        ]
    }
    return (
        <>
            <Helmet>
                <title>Salusion - Employer Resources</title>
                <meta name="title" content="FAQ: How does a QSEHRA allowance work?" />
                <meta name="description" content="a QSEHRA reimburses eligible expenses, up to a maximum annual amount, per plan year. Typically, your allowance is allocated in monthly increments, and unused allowance accrues throughout the plan year." />
                <script type="application/ld+json">{JSON.stringify(schema)}</script>
            </Helmet>

            <section className="first-section-full-background first-padding rules-all">
                <div className="container">
                    <h1>Learning Center</h1>

                    <div className="entry-container white-wrapper-all">
                        <h3>{question}</h3>
                        <hr className="divider" />

                        <p className="text"><b>How your allowance works</b></p>
                        <ul>
                            <li>An QSEHRA reimburses eligible expenses, up to a maximum annual amount, per plan year.
                            </li>
                            <li>
                                Salusion makes your allowance available in <b>monthly increments</b>, and unused allowance <b>accrues throughout the plan year</b>.
                            </li>
                            <li>
                                Expenses submitted are approved by Salusion on the <b>same day</b>.
                            </li>
                            <li>
                                Reimbursement is limited to the <b>allowance available at the time of submission</b>. Any unpaid portion of approved expenses will be automatically reimbursed when more allowance becomes available.
                            </li>
                            You have <b>75 business days</b> after the plan year ends to submit claims for expenses incurred during the previous plan year.
                            <ul>
                                <li>
                                    Remaining allowance from the previous plan year will be used to reimburse these claims.
                                </li>
                                <li>
                                    Any unused allowance after the run-out period is forfeited.
                                </li>
                            </ul>
                            <li>
                                If you leave the company, you can no longer submit claims for reimbursement. However, expenses submitted prior to departure will be reimbursed up to the available allowance.
                            </li>
                            <li>
                                Click <a href="https://www.youtube.com/watch?v=jLtJ1as1Np8">here</a> to watch a video on allowance mechanics.
                            </li>
                        </ul>

                        <p className="text"><b>Timing of Reimbursements</b></p>
                        <ul>
                            <li>Your employer determines whether approved expenses are reimbursed <b>internally</b> or via <b>ACH through Salusion</b>.</li>
                            <li>If reimbursed by ACH, payments are initiated <b>daily</b> or <b>monthly</b> based on the plan setup.</li>
                            <li>Once a reimbursement is initiated, it takes approximately <b>four business days</b> for the funds to reach your account.</li>
                            <li>On the Salusion portal dashboard, click <b>VIEW PLAN DETAILS</b> to see if reimbursements are processed via ACH and the next reimbursement processing date.</li>
                        </ul>

                        <p className="text"><b>Tracking Your Allowance</b><br />
                            The Salusion portal dashboard provides an overview of your QSEHRA allowance:</p>
                        <ol>
                            <li>
                                <b>Allowance Available Today</b> for reimbursement.
                            </li>
                            <li><b>Future Allowance</b> to be made available for the plan year.</li>
                            <li><b>Expenses Reimbursed</b> in the current plan year.</li>
                            <li><b>Expenses Pending Reimbursement</b>.</li>
                        </ol>

                        <p className="text"><b>Pending Expense Reimbursements</b></p>
                        <ul>
                            <li>At the bottom of the Salusion portal dashboard, you’ll find a list of <b>pending expenses</b> awaiting reimbursement.</li>
                            <li>Each entry displays the reimbursement <b>status</b>, the <b>amount</b>, and the <b>estimated date</b> the reimbursement will be deposited into your account.</li>
                        </ul>

                        <p className="text"><b>Submit All Receipts</b></p>
                        <ul>
                            <li>Even if your available allowance is insufficient, submit all receipts. Unreimbursed expenses are <b>queued for processing</b> as additional allowance becomes available.</li>
                        </ul>


                        <Link to="/learning-center" className="link">
                            <i className="back-icon bi bi-arrow-left" />
                            Back To Learning Center
                        </Link>


                    </div>
                </div>
            </section>
            <ResourceCenter>
                <ResourceCenterVideo
                    resourceImg={guideQsehra}
                    title="Provide tax-advantaged benefits simply and affordably with Salusion"
                    pageLink="/product/qsehra"
                />

                <ResourceCenterVideo
                    resourceImg={ichraVsQsehra}
                    title="Discover which HRA best fits your small business"
                    pageLink="/product/ichra-vs-qsehra"
                />
                <ResourceCenterVideo
                    resourceImg={qsehraRules}
                    title="Take a deep dive into the rules and regulations that govern QSEHRAs"
                    pageLink="/product/qsehra/rules-and-regulations"
                />
            </ResourceCenter>

        </>
    )
}
