import { Link } from "react-router-dom"
import "../styles.css"
import { Helmet } from "react-helmet-async"
import guideIchra from "../../../assets/guide-to-ichra.svg"
import guideQsehra from "../../../assets/guide-salusions.svg"
import ichravsqshera from "../../../assets/ichravsqsehra-poster.svg"
import ResourceCenter from "../../../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../../../components/Resource-Center/ResourceCenterVideo"

export const question = "What is minimum essential coverage?"

export default function LearningEntry() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": [
			{
				"@type": "Question",
				"name": "What is minimum essential coverage?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "Minimum Essential Coverage is an insurance plan that meets the ACA standards for a qualified health plan. A qualified health plan must be certified by the federal Health Insurance Marketplace, provide the following 10 essential health benefits required by the ACA and follow federal limits on fees like deductibles and copays.",
					"dateCreated": "2024-10-05T14:30:00+00:00",
				},
				"datePublished": "2024-10-05T14:30:00+00:00",
				"dateModified": "2024-10-05T14:30:00+00:00",
			},
		]
	}
	return (
		<>
			<Helmet>
				<title>Salusion - Employer Resources</title>
				<meta name="title" content="FAQ: What is minimum essential coverage?" />
				<meta name="description" content="Minimum Essential Coverage is an insurance plan that meets the ACA standards for a qualified health plan. A qualified health plan must be certified by the federal Health Insurance Marketplace, provide the following 10 essential health benefits required by the ACA and follow federal limits on fees like deductibles and copays." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>

			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							Minimum Essential Coverage is an insurance plan that meets the ACA standards for a qualified health plan.
							A qualified health plan must be certified by the federal Health Insurance Marketplace, provide the
							following 10 essential health benefits required by the ACA and follow federal limits on fees like
							deductibles and copays.
						</p>

						<ol>
							<li>Outpatient care</li>
							<li>Emergency services</li>
							<li>Hospitalization</li>
							<li>Pregnancy, maternity and newborn care</li>
							<li>Services for mental health and substance use disorders</li>
							<li>Prescription drugs</li>
							<li>Rehabilitation services and devices</li>
							<li>Laboratory services</li>
							<li>Preventive, wellness and chronic disease services</li>
							<li>Pediatric services</li>
						</ol>

						<p className="text">
							<b>Example of plans that count as Minimum Essential Coverage</b>
						</p>

						<ul>
							<li>Employer-sponsored coverage (including COBRA coverage and retiree coverage)</li>
							<li>Plans purchased on a state or federal health insurance exchange</li>

							<li>
								Individual health plans bought outside a health insurance exchange that meet the standards for qualified
								health plans
							</li>

							<li>Medicare Part A coverage and Medicare Advantage (Part C) plan</li>
							<li>Most Medicaid coverag</li>
							<li>Children's Health Insurance Program (CHIP) coverage</li>

							<li>
								Certain plans offered by the Department of Veterans Affairs (VA), including:
								<ul>
									<li>VA health care program</li>
									<li>Civilian Health and Medical Program (CHAMPVA)</li>
									<li>Spina bifida health care program</li>
								</ul>
							</li>

							<li>TRICARE</li>
							<li>Coverage provided to Peace Corps volunteers</li>
							<li>Coverage under the Nonappropriated Fund Health Benefit Program</li>
							<li>Refugee Medical Assistance supported by the Administration for Children and Families</li>
							<li>Self-funded health coverage offered to students by universities</li>
							<li>State high risk pool coverage established on or before November 26, 2014</li>
						</ul>

						<p className="text">
							<b>Example of plans that don’t count as Minimum Essential Coverage</b>
						</p>

						<ul>
							<li>Coverage only for vision care or dental care</li>
							<li>Workers' compensation</li>
							<li>Coverage only for a specific disease or condition</li>
							<li>Plans that offer only discounts on medical services</li>
							<li>Healthcare sharing ministries (Treasury letter 2016-0051)</li>
						</ul>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideIchra}
					title="A flexible, low-cost alternative to group coverage"
					pageLink="/product/ichra/video-guide"
				/>
				<ResourceCenterVideo
					resourceImg={guideQsehra}
					title="Provide tax-advantaged benefits simply and affordably with Salusion"
					pageLink="/product/qsehra/video-guide"
				/>
								<ResourceCenterVideo
					resourceImg={ichravsqshera}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>

			</ResourceCenter>
		</>
	)
}
