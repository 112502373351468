import Accordion from "react-bootstrap/Accordion"

export default function SalusionAccordion() {
	return (
		<Accordion>
			<Accordion.Item eventKey="0">
				<Accordion.Header>Compliance</Accordion.Header>
				<Accordion.Body>
					<ul>
						<li>Provides plan documentation</li>
						<li>Sends employee notifications</li>
						<li>Verifies employee’s insurance</li>
					</ul>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="1">
				<Accordion.Header>Expense Capture & Verification</Accordion.Header>
				<Accordion.Body>
					<ul>
						<li>Captures employee expenses via web portal and mobile app</li>
						<li>Verifies expenses on the same day</li>
					</ul>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="2">
				<Accordion.Header>Reimbursements</Accordion.Header>
				<Accordion.Body>
					<ul>
						<li>Automates reimbursements via ACH</li>
						<li>Supports direct payments to insurance carriers for premium-only ICHRAs</li>
					</ul>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="3">
				<Accordion.Header>Administrative Portal</Accordion.Header>
				<Accordion.Body>
					<ul>
						<li>Simplifies employee onboarding and offboarding</li>
						<li>Provides a comprehensive reporting suite</li>
						<li>Enables employers to modify plans and add third-party administrators as needed</li>
					</ul>
				</Accordion.Body>
			</Accordion.Item>
			<Accordion.Item eventKey="4">
				<Accordion.Header>Health Insurance</Accordion.Header>
				<Accordion.Body>
					<ul>
						<li>Simplifies employee access to marketplace plans</li>
						<li>Integrates with eHealth for off-marketplace plans</li>
						<li>Partners with local marketplace brokers for personalized service</li>
					</ul>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	)
}
