import { Helmet } from "react-helmet-async"

import PricingSection from "../components/Pricing/PricingSection"
import HraContact from "../components/Hra/HraContact"

export default function Pricing() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "Product",
		"name": "Salusion HRA Pricing",
		"description": "No mimimum usage, no setup costs, no platform fees, plan documents included.",
		"image": "https://salusion.com/assets/images/homepage/reimbursement.svg",
		"brand": {
			"@type": "Brand",
			"name": "Your Brand"
		},
		"offers": [
			{
				"@type": "Offer",
				"name": "ICHRA",
				"description": "Individual Coverage Health Reimbursement Arrangement (ICHRA)",
				"price": "14.00",
				"priceCurrency": "USD",
				"availability": "https://schema.org/InStock",
				"priceSpecification": {
					"@type": "UnitPriceSpecification",
					"price": "14.00",
					"priceCurrency": "USD",
					"billingDuration": 1,
					"billingIncrement": 1,
					"billingPeriod": "http://purl.org/goodrelations/v1#Month",
					"unitText": "participant/month",
				},
				"eligibleQuantity": {
					"@type": "QuantitativeValue",
					"value": 1,
					"unitText": "participant"
				},
				"shippingDetails": {
					"@type": "OfferShippingDetails",
					"shippingRate": {
						"@type": "MonetaryAmount",
						"value": "0.00",
						"currency": "USD"
					},
					"shippingDestination": {
						"@type": "DefinedRegion",
						"name": "United States"
					},
					"deliveryTime": {
						"@type": "QuantitativeValue",
						"value": "0",
						"unitCode": "DAY"
					}
				}
				},
			{
				"@type": "Offer",
				"name": "QSEHRA",
				"description": "Qualified Small Employer Health Reimbursement Arrangement (QSEHRA)",
				"price": "14.00",
				"priceCurrency": "USD",
				"availability": "https://schema.org/InStock",
				"priceSpecification": {
					"@type": "UnitPriceSpecification",
					"price": "14.00",
					"priceCurrency": "USD",
					"billingDuration": 1,
					"billingIncrement": 1,
					"billingPeriod": "http://purl.org/goodrelations/v1#Month",
					"unitText": "participant/month",
				},
				"eligibleQuantity": {
					"@type": "QuantitativeValue",
					"value": 1,
					"unitText": "participant"
				},
			},
			{
				"@type": "Offer",
				"name": "GCHRA",
				"description": "Group Coverage Health Reimbursement Arrangement (ICHRA)",
				"price": "5.00",
				"priceCurrency": "USD",
				"availability": "https://schema.org/InStock",
				"priceSpecification": {
					"@type": "UnitPriceSpecification",
					"price": "5.00",
					"priceCurrency": "USD",
					"billingDuration": 1,
					"billingIncrement": 1,
					"billingPeriod": "http://purl.org/goodrelations/v1#Month",
					"unitText": "participant/month",
				},
				"eligibleQuantity": {
					"@type": "QuantitativeValue",
					"value": 1,
					"unitText": "participant"
				},
			},
			{
				"@type": "Offer",
				"name": "EBHRA",
				"description": "Excepted Benefit Health Reimbursement Arrangement (ICHRA)",
				"price": "5.00",
				"priceCurrency": "USD",
				"availability": "https://schema.org/InStock",
				"priceSpecification": {
					"@type": "UnitPriceSpecification",
					"price": "5.00",
					"priceCurrency": "USD",
					"billingDuration": 1,
					"billingIncrement": 1,
					"billingPeriod": "http://purl.org/goodrelations/v1#Month",
					"unitText": "participant/month",
				},
				"eligibleQuantity": {
					"@type": "QuantitativeValue",
					"value": 1,
					"unitText": "participant"
				},
			},
		]
	}

	return (
		<>
			<Helmet>
				<title>Salusion - Pricing</title>
				<meta name="title" content="Salusion HRA Pricing" />
				<meta name="description" content="No mimimum usage, no setup costs, no platform fees, plan documents included." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>
			<PricingSection />
			<HraContact />
		</>
	)
}
