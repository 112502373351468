/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useLocation, useSearchParams } from "react-router-dom"
import { sendVisitorInfo } from "."

export default async function useVisitorTracking() {
	const location = useLocation()
	const searchParams = useSearchParams()[0]

	const route = location.pathname
	const payload = { url: route }

	for (const [key, value] of searchParams) {
		payload[key] = value
	}

	if (route.startsWith("/product") && route.split("/")[2]) {
		payload.product = route.split("/")[2].toUpperCase()
	}

	useEffect(() => {
		sendVisitorInfo(payload)
	}, [route])
}
