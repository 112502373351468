import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import TestimonialCard from "./TestimonialCard"

import "swiper/css"
import "swiper/css/navigation"

export default function WorkersSlider() {
	return (
		<>
			<Swiper
				modules={[Navigation]}
				spaceBetween={32}
				slidesPerView={5.5}
				// navigation={true}
				loop
				navigation={{
					nextEl: ".image-swiper-button-next",
					prevEl: ".image-swiper-button-prev"
				}}
				breakpoints={{
					0: {
						slidesPerView: 1
					},
					767: {
						slidesPerView: 2.5
					},
					1200: {
						slidesPerView: 4.5
					}
				}}
			>
				<SwiperSlide>
					<TestimonialCard
						name="James Holden 2"
						position="CEO of Starship Freight"
						text="Exceptional service and support! Our employees appreciate the flexibility and coverage options."
						hashtags="#TrustedPartner #EmployeeCare"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<TestimonialCard
						name="James Holden 2"
						position="CEO of Starship Freight"
						text="Exceptional service and support! Our employees appreciate the flexibility and coverage options."
						hashtags="#TrustedPartner #EmployeeCare"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<TestimonialCard
						name="James Holden 2"
						position="CEO of Starship Freight"
						text="Exceptional service and support! Our employees appreciate the flexibility and coverage options."
						hashtags="#TrustedPartner #EmployeeCare"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<TestimonialCard
						name="James Holden 2"
						position="CEO of Starship Freight"
						text="Exceptional service and support! Our employees appreciate the flexibility and coverage options."
						hashtags="#TrustedPartner #EmployeeCare"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<TestimonialCard
						name="James Holden 2"
						position="CEO of Starship Freight"
						text="Exceptional service and support! Our employees appreciate the flexibility and coverage options."
						hashtags="#TrustedPartner #EmployeeCare"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<TestimonialCard
						name="James Holden 2"
						position="CEO of Starship Freight"
						text="Exceptional service and support! Our employees appreciate the flexibility and coverage options."
						hashtags="#TrustedPartner #EmployeeCare"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<TestimonialCard
						name="James Holden 2"
						position="CEO of Starship Freight"
						text="Exceptional service and support! Our employees appreciate the flexibility and coverage options."
						hashtags="#TrustedPartner #EmployeeCare"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<TestimonialCard
						name="James Holden 2"
						position="CEO of Starship Freight"
						text="Exceptional service and support! Our employees appreciate the flexibility and coverage options."
						hashtags="#TrustedPartner #EmployeeCare"
					/>
				</SwiperSlide>
			</Swiper>
		</>
	)
}
