import { Field } from "formik"
import FormButtons from "../../FormButtons"

export default function PremiumStartDate({ step, setStep }) {
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"Septembar",
		"October",
		"November",
		"December"
	]

	const currentYear = "2025"

	return (
		<>
			<div className="form-content-wrapper">
				<h2>Employer First Payment Date</h2>

				<div className="first-payment-date-wrapper">
					<p>Select the first coverage month when.</p>
					<h4>Select the first coverage month when your employer will start paying your insurance premium</h4>
					<p>Employer to start paying premiums on:</p>

					<div className="form-group">
						<Field as="select" name="paidPremiumStartDate">
							{months.map((month, index) => (
								<option key={index} value={month}>
									{month} {currentYear}
								</option>
							))}
						</Field>
					</div>
				</div>
			</div>

			<FormButtons step={step} setStep={setStep} />
		</>
	)
}
