import { Link } from "react-router-dom"
import "../styles.css"
import { Helmet } from "react-helmet-async"
import guideQsehra from "../../../assets/guide-salusions.svg"
import ichraVsQsehra from "../../../assets/ichravsqsehra-poster.svg"
import qsehraRules from "../../../assets/qsehra-rules.svg"
import ResourceCenter from "../../../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../../../components/Resource-Center/ResourceCenterVideo"

export const question = "What is the insurance requirement for a QSEHRA?"

export default function LearningEntry() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": [
			{
				"@type": "Question",
				"name": "What is the insurance requirement for a QSEHRA?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "QSEHRAs may only pay or reimburse medical expenses after the employee provides proof of minimum essential coverage (MEC), and each individual (including dependents) must have MEC the month in which an expense is incurred.",
					"dateCreated": "2024-10-05T14:30:00+00:00",
				},
				"datePublished": "2024-10-05T14:30:00+00:00",
				"dateModified": "2024-10-05T14:30:00+00:00",
			},
		]
	}
	return (
		<>
			<Helmet>
				<title>Salusion - Employer Resources</title>
				<meta name="title" content="FAQ: What is the insurance requirement for a QSEHRA?" />
				<meta name="description" content="QSEHRAs may only pay or reimburse medical expenses after the employee provides proof of minimum essential coverage (MEC), and each individual (including dependents) must have MEC the month in which an expense is incurred." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>

			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							QSEHRAs may only pay or reimburse medical expenses after the employee provides proof of minimum essential
							coverage (MEC), and each individual (including dependents) must have MEC the month in which an expense is
							incurred.
						</p>

						<p className="text">Examples of qualifying MEC:</p>

						<ul>
							<li>Plans purchased on a state or federal health insurance exchange</li>

							<li>
								Individual health plans bought outside a health insurance exchange that meet the standards for qualified
								health plans
							</li>

							<li>Employer-sponsored plans including COBRA and retiree plans</li>
							<li>Coverage under a parent's marketplace or job-based plan</li>
							<li>Most student health plans</li>
							<li>Medicare Part A</li>
							<li>Medicare Advantage plans, also known as Medicare Part C</li>
							<li>Most Medicaid coverage, except for limited coverage plans</li>
							<li>Children's Health Insurance Program (CHIP)</li>
							<li>Most TRICARE plans, also known as uniformed services health care programs</li>
							<li>Certain plans offered by the Department of Veterans Affairs (VA), including:</li>

							<ul>
								<li>VA health care program</li>
								<li>Civilian Health and Medical Program (CHAMPVA)</li>
								<li>Spina bifida health care program</li>
							</ul>

							<li>Health coverage for Peace Corps volunteers</li>
							<li>State high-risk pool plans that became effective on or before Dec. 31, 2014</li>
							<li>Department of Defense Nonappropriated Fund (DoD NAF) Health Benefits Program</li>
							<li>Refugee Medical Assistance (RMA) plans from the Administration for Children and Families</li>
						</ul>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideQsehra}
					title="Provide tax-advantaged benefits simply and affordably with Salusion"
					pageLink="/product/qsehra"
				/>

				<ResourceCenterVideo
					resourceImg={ichraVsQsehra}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>
				<ResourceCenterVideo
					resourceImg={qsehraRules}
					title="Take a deep dive into the rules and regulations that govern QSEHRAs"
					pageLink="/product/qsehra/rules-and-regulations"
				/>
			</ResourceCenter>
		</>
	)
}
