import axios from "axios"

class API {
	send(request) {
		const exactURL = request.exactURL
		const token = request.token

		const api = axios.create()
		api.interceptors.request.use((request) => onRequest(request, exactURL, token))
		api.interceptors.response.use(onSuccess, request.handleError || onError)

		return api(request)
	}

	get(request) {
		return this.send({ method: "GET", ...request })
	}

	post(request) {
		return this.send({ method: "POST", ...request })
	}

	patch(request) {
		return this.send({ method: "PATCH", ...request })
	}

	delete(request) {
		return this.send({ method: "DELETE", ...request })
	}

	upload(url, file, request) {
		const headers = { "Content-Type": "multipart/form-data" }
		const data = new FormData()

		const fileName = request.fileName
		const payload = request.payload

		data.append("upload", file)
		if (Boolean(fileName)) data.append("name", fileName)

		if (Boolean(payload)) {
			for (const key in payload) {
				data.append(key, payload[key])
			}
		}

		return this.send({ method: "POST", url, data, headers })
	}
}

function onRequest(request, exactURL, token) {
	const hosts = {
		localhost: `https://backend.salusion-staging.com/v1/`,
		"salusion.com": "https://backend.salusion.com/v1/",
		"salusion-staging.com": `https://backend.salusion-staging.com/v1/`,
		"salusion-preprod.com": `https://backend.salusion-preprod.com/v1/`
	}

	const host = hosts[window.location.hostname] || "https://backend.salusion.com/v1/"

	if (!(exactURL || request.url.includes(host))) {
		if (request.url.startsWith("/")) request.url = host + request.url.slice(1)
		else request.url = host + request.url
	}

	if (token) request.headers.authorization = `Bearer ${token}`
	return request
}

function onSuccess(response) {
	return response
}

function onError(error) {
	return Promise.reject(error)
}

const api = new API()
export default api
