import { toKebabCase } from "."

export default function getLearningEntryRoutes(entries) {
	return Object.entries(entries).map((route) => {
		const [key, entry] = route
		const { question, default: Component } = entry

		return {
			question,
			path: `/learning-center/${toKebabCase(key)}`,
			element: <Component />
		}
	})
}
