import { useState, useEffect, useRef } from "react"
import { Link } from "react-router-dom"

export default function TestimonialCard(props) {
	const { name, position, text, hashtags } = props

	const textContainerRef = useRef()
	const [showExpandButton, setShowExpandButton] = useState(() => false)
	const [expandText, setExpandText] = useState(() => false)

	let height = "125px"
	if (expandText) height = "auto"
	else if (!showExpandButton) height = "150px"

	const styles = {
		ratingsContainer: {
			marginTop: "1rem"
		},

		starIcon: {
			marginRight: "2px",
			color: "#ffdc7f"
		},

		textContainer: {
			height: height,
			display: "-webkit-box",
			overflow: "hidden",
			WebkitBoxOrient: "vertical",
			WebkitLineClamp: expandText ? 99 : 5
		},

		link: {
			color: "#6c63ff"
		}
	}

	useEffect(() => {
		const { scrollHeight, clientHeight } = textContainerRef.current
		if (scrollHeight !== clientHeight) setShowExpandButton(true)
	}, [])

	return (
		<>
			<div className="testimonial-card-single">
				<div className="testimonial-header">
					<h4>{name}</h4>
					{Boolean(position) && <p>{position}</p>}
				</div>

				<div style={styles.ratingsContainer}>
					<i className="bi bi-star-fill" style={styles.starIcon} />
					<i className="bi bi-star-fill" style={styles.starIcon} />
					<i className="bi bi-star-fill" style={styles.starIcon} />
					<i className="bi bi-star-fill" style={styles.starIcon} />
					<i className="bi bi-star-fill" style={styles.starIcon} />
				</div>

				<div className="testimonial-review">
					<p ref={textContainerRef} style={styles.textContainer}>
						{text}
					</p>
				</div>

				{showExpandButton && (
					<Link style={styles.link} onClick={() => setExpandText(!expandText)}>
						{expandText ? "Read Less" : "Read More"}
					</Link>
				)}

				{Boolean(hashtags) && (
					<div className="testimonials-hashtags">
						<span>{hashtags}</span>
					</div>
				)}
			</div>
		</>
	)
}
