import FormButtons from "../../FormButtons"

export default function Ineligible({ step, setStep }) {
	return (
		<>
			<div className="form-content-wrapper">
				<h2>Ineligible</h2>

				<div className="ineligible-wrapper">
					<p>To participate in your company's ICHRA, you must be insured by either</p>

					<div className="list-wrapper">
						<ul>
							<li>Individual Coverage Health Insurance</li>
							<li>Medicare</li>
						</ul>
					</div>

					<p>
						If your circumstance changes and you want to participate, use the link in the onboarding email or ask your
						employer to reinvite you.
					</p>
				</div>
			</div>

			<FormButtons step={step} setStep={setStep} hideNext={true} />
		</>
	)
}
