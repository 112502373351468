import PackageSingle from "./PackageSingle"

import ichra from "../../../assets/ichra.png"
import qsehra from "../../../assets/qsehra.png"
import gchra from "../../../assets/gchra.png"
import ebhra from "../../../assets/ebhra.png"

export default function CompanyPackages() {
	return (
		<section id="hra_company">
			<div className="container">
				<div className="hra-wrapper">
					<h2>An HRA for Your Company</h2>
					<div className="row">
						<PackageSingle
							img={ichra}
							title="ICHRA"
							smallHeading="Individual Coverage HRA"
							text="An alternative to group coverage that is more flexible, lower cost, and easier to administer."
							price="14"
							link="/product/ichra"
						/>
						<PackageSingle
							img={qsehra}
							title="QSEHRA"
							smallHeading="Qualified Small Employer HRA"
							text="A health benefit for all employees offered by small companies without group coverage."
							price="14"
							link="/product/qsehra"
						/>
						<PackageSingle
							img={gchra}
							title="GCHRA"
							smallHeading="Group Coverage HRA"
							text="Enhancement to a group coverage, generally by lowering deductibles or filling coverage gaps."
							price="5"
							link="/product/gchra"
						/>
						<PackageSingle
							img={ebhra}
							title="EBHRA"
							smallHeading="Excepted Benefits HRA"
							text="A health benefit for all employees offered by companies with group coverage."
							price="5"
							link="/product/ebhra"
						/>
					</div>
				</div>
			</div>
		</section>
	)
}
