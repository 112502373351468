import { Field } from "formik"
import FormButtons from "../../FormButtons"

export default function Affordability({ step, setStep }) {
	return (
		<>
			<div className="form-content-wrapper">
				<h2>Check Affordability</h2>

				<div className="check-affordability-wrapper d-flex">
					<div className="form-group d-flex-50">
						<label>
							<span>Please Enter Your Zipcode</span>
							<Field type="number" name="zip" placeholder="78250" />
						</label>
					</div>

					<div className="form-group d-flex-50">
						<label>
							<span>Please Enter Your Household Income</span>
							<Field type="number" name="income" placeholder="0" />
						</label>
					</div>

					<div className="form-group d-flex-50">
						<label>
							<span>An affordable ICHRA is</span>
							<Field type="number" name="affordableICHRA" placeholder="0" />
						</label>
					</div>

					<div className="form-group d-flex-50">
						<label>
							<span>Your self-only ICHRA is</span>
							<Field type="number" name="selfICHRA" placeholder="0" />
						</label>
					</div>

					<div className="form-group form-group-radio d-flex-50">
						<span>Your ICHRA is</span>

						<label>
							<Field type="radio" name="affordable" value="Yes" />
							<span>Affordable</span>
						</label>

						<label>
							<Field type="radio" name="affordable" value="No" />
							<span>Unaffordable</span>
						</label>
					</div>
				</div>
			</div>

			<FormButtons hideNext={true} step={step} setStep={setStep} />
		</>
	)
}
