import { Helmet } from "react-helmet-async"

import "../components/Products/Products.css"
import ProductsHero from "../components/Products/ProductsHero"
import HraContact from "../components/Hra/HraContact"
import PriceComparison from "../components/Products/PriceComparison"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"

import ichravsqshera from "../assets/ichravsqsehra-poster.svg"
import ichraRules from "../assets/ichraRules.svg"
import employees from "../assets/50-employees.svg"
import ichraCalculator from "../assets/ichra-calculator.svg"
import reimbursement from "../assets/reimbursement-options.svg"
import guideIchra from "../assets/guide-to-ichra.svg"

import { PRICING_DATA_ICHRA } from "../components/Products/data"
import { TABLE_DATA_ICHRA } from "../components/Products/data"

export default function Ichra() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "Product",
		"name": "Individual Coverage Health Reimbursement Arrangement (ICHRA)",
		"description": "Salusion’s ICHRA software simplifies administration and helps employers efficiently manage tax-free health reimbursements.  Start managing your ICHRA today with the industry’s most cost-effective solution, backed by top-tier customer service.",
		"brand": {
			"@type": "Brand",
			"name": "Salusion"
		},
		"offers": {
			"@type": "Offer",
			"price": "14.00",
			"priceCurrency": "USD",
			"availability": "https://schema.org/InStock",
			"priceSpecification": {
				"@type": "UnitPriceSpecification",
				"price": "14.00",
				"priceCurrency": "USD",
				"billingDuration": 1,
				"billingIncrement": 1,
				"billingPeriod": "http://purl.org/goodrelations/v1#Month",
				"unitText": "participant/month",
			},
			"eligibleQuantity": {
				"@type": "QuantitativeValue",
				"value": 1,
				"unitText": "participant"
			},
			"shippingDetails": {
				"@type": "OfferShippingDetails",
				"shippingRate": {
					"@type": "MonetaryAmount",
					"value": "0.00",
					"currency": "USD"
				},
				"shippingDestination": {
					"@type": "DefinedRegion",
					"name": "United States"
				},
				"deliveryTime": {
					"@type": "QuantitativeValue",
					"value": "0",
					"unitCode": "DAY"
				}
			}
		},
		"image": "https://salusion.com/assets/images/homepage/reimbursement.svg",
		"url": "https://www.salusion.com/product/ichra",
		"datePublished": "2024-10-05T14:30:00+00:00",
		"dateModified": "2024-10-05T14:30:00+00:00",

	}
	return (
		<>
			<Helmet>
				<title>Salusion - ICHRA</title>
				<meta name="title" content="Simple and Affordable ICHRA Administration | Salusion" />
				<meta name="description" content="Salusion’s ICHRA software simplifies administration and helps employers efficiently manage tax-free health reimbursements.  Start managing your ICHRA today with the industry’s most cost-effective solution, backed by top-tier customer service." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>
			<ProductsHero
				title="Simple, Affordable ICHRAs"
				subtitle="Salusion’s ICHRA software simplifies administration and helps employers manage tax-free health reimbursements.  Start your ICHRA today with the industry’s most cost-effective solution."
				video="https://www.youtube.com/embed/QI1M_6qYVao?si=uHs0hXI4W37s__Ff"
				poster={guideIchra}
				autoPlay={false}
			/>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={ichravsqshera}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>
				<ResourceCenterVideo
					resourceImg={ichraRules}
					title="Take a deep dive into the rules and regulations that govern ICHRAs"
					pageLink="/product/ichra/rules-and-regulations"
				/>
				<ResourceCenterVideo
					resourceImg={employees}
					title="ICHRA topics for large employers"
					pageLink="/product/ichra/large-business"
				/>
				<ResourceCenterVideo
					resourceImg={ichraCalculator}
					title="Discover how much you can save with an ACA-compliant ICHRA"
					externalLink="product/ichra/aca-compliant-calculator"
				/>
				{/* <ResourceCenterVideo
					resourceImg={findBroker}
					title="Find a broker in your state that can help your employee find insurance"
					pageLink="/product/ichra/find-brokers"
				/> */}
				<ResourceCenterVideo
					resourceImg={reimbursement}
					title="Discussion of the pros and cons of each reimbursement option"
					pageLink="/product/ichra/reimbursement-options"
				/>
			</ResourceCenter>
			<PriceComparison
				dataPricing={PRICING_DATA_ICHRA}
				dataCheckmark={TABLE_DATA_ICHRA}
				title="ICHRA Price Comparison"
			/>
			<HraContact />
		</>
	)
}
