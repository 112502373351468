import { Field } from "formik"

import FormButtons from "../../FormButtons"
import { InsuranceType, Participation, Affordability } from "."
import { CreateAccount, Ineligible } from "."

export default function Agreement({ step, setStep, steps, setSteps, insuranceType }) {
	function handleAccept() {
		if (insuranceType === "INDIVIDUAL_COVERAGE") {
			setSteps([InsuranceType, Participation, Affordability, Agreement, CreateAccount])
		} else if (insuranceType === "MEDICARE") {
			setSteps([InsuranceType, Agreement, CreateAccount])
		}

		setStep(step + 1)
	}

	function handleDecline() {
		setSteps([...steps.slice(0, step + 1), Ineligible])
		setStep(step + 1)
	}

	return (
		<>
			<div className="form-content-wrapper">
				<h2>ICHRA Agreement</h2>
				<h4>A full description of your ICHRA benefit is provided in the following documents:</h4>

				<div className="list-wrapper">
					<ul>
						<li>ICHRA Summary Plan Document</li>
						<li>ICHRA Appendix A</li>
					</ul>
				</div>

				<div className="agreement-field">
					<h4>Your ICHRA benefit on a monthly basis is:</h4>

					<div className="check-affordability-wrapper d-flex">
						<div className="form-group d-flex-50">
							<label>
								<span>Please Enter Your Zipcode</span>
								<Field type="number" name="zip" placeholder="78250" />
							</label>
						</div>
					</div>

					<h4 className="accept-heading">Do you wish to accept or decline your ICHRA benefit?</h4>
				</div>
			</div>

			<FormButtons step={step} setStep={setStep} hideNext={true}>
				<button type="button" className="btn-back" onClick={handleDecline}>
					Decline
				</button>

				<button type="submit" className="btn-next" onClick={handleAccept}>
					Accept
				</button>
			</FormButtons>
		</>
	)
}
