import React, { useState, useEffect } from "react"

import PricingTab from "./PricingTab"
import "./Pricing.css"

const texts = ["Plan Documents Included", "No Setup Costs", "No Minimum Usage", "No Platform Fees"]

export default function PricingSection() {
	const [currentText, setCurrentText] = useState(0)
	const [opacity, setOpacity] = useState(1)

	useEffect(() => {
		const interval = setInterval(() => {
			setOpacity(0)

			setTimeout(() => {
				setCurrentText((prevText) => (prevText + 1) % texts.length)
				setOpacity(1)
			}, 500)
		}, 3000)

		return () => clearInterval(interval)
	}, [])

	return (
		<section id="pricing" className="first-section first-padding">
			<div className="container">
				<div className="pricing-wrapper white-wrapper-all">
					<h1 style={{ transition: "opacity 0.5s", opacity: opacity }}>{texts[currentText]}</h1>
					<div className="pricing-wrapper-row">
						<div className="row">
							<PricingTab
								title="Plans Offered Without Group Coverage"
								price="14"
								smallHeadingFirst="ICHRA"
								smallTextOne="Individual Coverage Health Reimbursement Arrangement"
								smallHeadingSec="QSEHRA"
								smallTextSec="Qualified Small Employer Health Reimbursement Arrangement"
							/>
							<PricingTab
								title="Plans Offered With Group Coverage"
								price="5"
								smallHeadingFirst="GCHRA"
								smallTextOne="Group Coverage Health Reimbursement Arrangement"
								smallHeadingSec="EBHRA"
								smallTextSec="Excepted Benefit Health Reimbursement Arrangement"
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
