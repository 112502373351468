import { Link } from "react-router-dom"
import "../styles.css"
import { Helmet } from "react-helmet-async"
import guideEbhra from "../../../assets/guide-ebhra.svg"
import ResourceCenter from "../../../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../../../components/Resource-Center/ResourceCenterVideo"

export const question = "What is the maximum annual allowance for an EBHRA in 2025?"

export default function LearningEntry() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": [
			{
				"@type": "Question",
				"name": "What is the maximum annual allowance for an EBHRA in 2025?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "In 2025, the maximum amount available under an EBHRA is $2,150.",
					"dateCreated": "2024-10-05T14:30:00+00:00",
				},
				"datePublished": "2024-10-05T14:30:00+00:00",
				"dateModified": "2024-10-05T14:30:00+00:00",
			},
		]
	}
	return (
		<>
			<Helmet>
				<title>Salusion - Employer Resources</title>
				<meta name="title" content="FAQ: What is the maximum annual allowance for an EBHRA in 2025?" />
				<meta name="description" content="In 2025, the maximum amount available under an EBHRA is $2,150." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>

			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">In 2025, the maximum amount available under an EBHRA is $2,150.</p>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideEbhra}
					title="Simple, Affordable EBHRAs"
					pageLink="/product/ebhra"
				/>

			</ResourceCenter>
		</>
	)
}
