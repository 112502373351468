/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react"
import { useLocation, useSearchParams, Link } from "react-router-dom"
import { sendVisitorInfo, toSalusionPrivate } from "../../util"

export default function ProductsHero({ title, subtitle, video, buttonsHide, poster, autoPlay }) {
	const location = useLocation()
	const searchParams = useSearchParams()[0]

	const [showVideo, setShowVideo] = useState(() => false)

	function scrollToContact() {
		document.getElementById("start_hra").scrollIntoView({ behavior: "smooth" })
	}

	function getVisitorInfo() {
		const route = location.pathname
		const payload = { url: route, video: encodeURIComponent(video) }

		for (const [key, value] of searchParams) {
			payload[key] = value
		}

		if (route.startsWith("/product") && route.split("/")[2]) {
			payload.product = route.split("/")[2].toUpperCase()
		}

		return payload
	}

	async function playVideo() {
		const visitorInfo = getVisitorInfo()
		await sendVisitorInfo(visitorInfo)

		setShowVideo(true)
	}

	useEffect(() => {
		if (autoPlay) {
			setTimeout(playVideo, 0)
		}
	}, [])

	return (
		<section id="products_hero" className="first-padding first-section-full-background">
			<div className="container">
				<div className="salusion-guide-wrapper">
					<h1>{title}</h1>
					<p>{subtitle}</p>

					{!buttonsHide && (
						<div className="salusion-guide-btn-wrapper">
							<Link onClick={(event) => toSalusionPrivate(event, "/create-account", false)}>
								Start Your Company's HRA
							</Link>

							<Link onClick={scrollToContact}>Speak with an Expert</Link>
						</div>
					)}

					<div className="salusion-product-video">
						{!showVideo && <img src={poster} alt={title} onClick={playVideo} />}

						{showVideo && (
							<iframe
								src={`${video}&autoplay=1&mute=1`}
								title={title}
								allow="autoplay encrypted-media"
								allowFullScreen
								referrerPolicy="strict-origin-when-cross-origin"
								frameBorder="0"
							/>
						)}
					</div>
				</div>
			</div>
		</section>
	)
}
