import { Helmet } from "react-helmet-async"

import CreateAccountHero from "../components/Create-Account/CreateAccountHero"
import "../components/Create-Account/CreateAccount.css"

export default function CreateAccount() {
	return (
		<>
			<Helmet>
				<title>Salusion - Create Account</title>
			</Helmet>
			<CreateAccountHero />
		</>
	)
}
