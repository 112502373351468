import { Field, ErrorMessage } from "formik"
import FormButtons from "../../FormButtons"

import usa from "../../../../../assets/usa-img.svg"

export default function CheckAddress({ step, setStep }) {
	return (
		<>
			<div className="form-content-wrapper">
				<div className="payment-instructions-continued">
					<div className="heading-payment-wrapper">
						<h2>
							Direct Payment <br /> Instructions (continued)
						</h2>

						<img src={usa} alt="usa" />
					</div>

					<div className="instructions-wrapper d-flex">
						<div className="d-flex-100 form-group">
							<Field type="text" placeholder="Send To Name" name="providerCheckRecipient" />
							<ErrorMessage name="providerCheckRecipient" component="div" className="error-message-fields" />
						</div>

						<div className="d-flex-100 form-group">
							<Field type="text" placeholder="Send To Address" name="providerAddress1" />
							<ErrorMessage name="providerAddress1" component="div" className="error-message-fields" />
						</div>

						<div className="d-flex-100 form-group">
							<Field type="text" placeholder="Send To Address" name="providerAddress2" />
							<ErrorMessage name="providerAddress2" component="div" className="error-message-fields" />
						</div>

						<div className="d-flex-33 form-group">
							<Field type="text" placeholder="City" name="providerCity" />
							<ErrorMessage name="providerCity" component="div" className="error-message-fields" />
						</div>

						<div className="d-flex-33 form-group">
							<Field type="text" placeholder="State" name="providerState" />
							<ErrorMessage name="providerState" component="div" className="error-message-fields" />
						</div>

						<div className="d-flex-33 form-group">
							<Field type="number" placeholder="Zip" name="providerZip" />
							<ErrorMessage name="providerZip" component="div" className="error-message-fields" />
						</div>
					</div>

					<p>
						Please verify that the information on the payment envelope is correct and meets the specification of your
						carrier.
					</p>
				</div>
			</div>

			<FormButtons step={step} setStep={setStep} />
		</>
	)
}
