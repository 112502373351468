import simplifies from "../../../assets/salusion-simplifies.svg"

import SalusionAccordion from "./SalusionAccordion"

export default function SalusionSimplifies() {
	return (
		<section id="salusion_simplifies">
			<div className="row align-items-center">
				<div className="col-12 col-lg-8 col-md-6">
					<div className="simplifies-img">
						<img
							src={simplifies}
							alt="salusion simplifies the administration of tax-advantaged health reimbursement arrangements"
						/>
					</div>
				</div>
				<div className="col-12 col-lg-4 col-md-6">
					<div className="simplifies-accordion">
						<h2>
							Salusion Simplifies <br /> HRA Administration
						</h2>
						<SalusionAccordion />
					</div>
				</div>
			</div>
		</section>
	)
}
