export default {
	paymentOption: "ACH",

	bankName: "",
	nickname: "",
	routingNumber: "",
	accountNumber: "",
	accountType: "",

	paidPremiumStartDate: "",
	providerCheckRecipient: "",
	providerAddress1: "",
	providerAddress2: "",
	providerCity: "",
	providerState: "",
	providerZip: "",

	payrollAttest: "",
	insuranceAttest: ""
}
