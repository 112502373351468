import React, { useState } from "react"
import Calendly from "../Calendly"
import Input from "../Input"
import { api, validatePayload } from "../../util"
import "./Contact.css"

export default function Contact() {
	const [contact, setContact] = useState(() => getBlankContact())
	const [errors, setErrors] = useState(() => [])
	const [notification, setNotification] = useState(() => false)

	function getBlankContact() {
		return { name: "", email: "", message: "" }
	}

	function handleInput(event) {
		const { name, value } = event.target

		const updatedContact = { ...contact, [name]: value }
		const errors = validatePayload(updatedContact)

		setContact(updatedContact)
		setErrors(errors)
	}

	async function handleSubmit(event) {
		event.preventDefault()

		if (!errors.length) {
			const url = "send/webContact"
			await api.post({ url, data: contact })

			setContact(getBlankContact())
			setNotification(true)

			setTimeout(() => {
				setNotification(false)
			}, 3000)
		}
	}

	return (
		<section id="contact" className="first-section first-padding">
			<div className="container">
				<div className="contact-wrapper white-wrapper-all">
					<h1>We Look Forward To Connecting With You</h1>

					<div className="contact-form-wrapper">
						<div className="row">
							<div className="col-12 col-md-7">
								<div style={{ textAlign: "center" }}>
									<h2>Schedule a call</h2>
									<Calendly calendarOnly={true} />
								</div>
							</div>

							<div className="col-12 col-md-5">
								<div style={{ textAlign: "center" }}>
									<h2>Send a message</h2>
									{notification && <p>Message sent successfully. We will respond to you shortly.</p>}

									<form className="form-all contact-form" onSubmit={handleSubmit}>
										<Input type="text" name="name" placeholder="Name" value={contact.name} onChange={handleInput} />
										<Input type="email" name="email" placeholder="Email" value={contact.email} onChange={handleInput} />

										<div className="col-12">
											<div className="form-group">
												<textarea
													className="form-control"
													name="message"
													placeholder="Message"
													value={contact.message}
													onChange={handleInput}
												/>
											</div>
										</div>

										<button type="submit">Send Message</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}
