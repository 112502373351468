import { Link } from "react-router-dom"
import "../styles.css"
import { Helmet } from "react-helmet-async"
import guideQsehra from "../../../assets/guide-salusions.svg"
import ichraVsQsehra from "../../../assets/ichravsqsehra-poster.svg"
import qsehraRules from "../../../assets/qsehra-rules.svg"
import ResourceCenter from "../../../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../../../components/Resource-Center/ResourceCenterVideo"

export const question = "What is the definition of a seasonal employee for an ICHRA and a QSEHRA?"

export default function LearningEntry() {
	return (
		<>
			<Helmet>
				<title>Salusion - Employer Resources</title>
				<meta name="title" content="FAQ: What is the definition of a seasonal employee for an ICHRA and a QSEHRA?" />
				<meta name="description" content="Seasonal employees are defined under the Code §4980H or the Code §105 regulations, as elected by the HRA sponsor." />
			</Helmet>

			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							Seasonal employees are defined under the Code §4980H or the <a href="https://www.law.cornell.edu/cfr/text/26/1.105-11">Code §105</a> regulations, as elected by the HRA
							sponsor.
						</p>

						<ul>
							<li>
								Using Code §4980H, a seasonal employee performs labor or services on a seasonal basis as defined by the
								Secretary of Labor, including workers covered by section 500.20(s)(1) of title 29, Code of Federal
								Regulations and retail workers employed exclusively during holiday seasons
							</li>

							<li>
								Using <a href="https://www.law.cornell.edu/cfr/text/26/1.105-11">Code §105</a>, employees whose customary annual employment is less than 9 months, if other employees
								in similar work with the same employer (or, if no employees of the employer are in similar work, in
								similar work in the same industry and location) have substantially more months.
							</li>
						</ul>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideQsehra}
					title="Provide tax-advantaged benefits simply and affordably with Salusion"
					pageLink="/product/qsehra"
				/>

				<ResourceCenterVideo
					resourceImg={ichraVsQsehra}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>
				<ResourceCenterVideo
					resourceImg={qsehraRules}
					title="Take a deep dive into the rules and regulations that govern QSEHRAs"
					pageLink="/product/qsehra/rules-and-regulations"
				/>
			</ResourceCenter>
		</>
	)
}
