import { Link } from "react-router-dom"
import "../styles.css"
import { Helmet } from "react-helmet-async"
import guideQsehra from "../../../assets/guide-salusions.svg"
import ichraVsQsehra from "../../../assets/ichravsqsehra-poster.svg"
import qsehraRules from "../../../assets/qsehra-rules.svg"
import ResourceCenter from "../../../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../../../components/Resource-Center/ResourceCenterVideo"

export const question = "What are the employer requirements to offer a QSEHRA?"

export default function LearningEntry() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": [
			{
				"@type": "Question",
				"name": "What are the employer requirements to offer a QSEHRA?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "An employer is eligible to adopt a QSEHRA only if both of the following requirements are met: the employer is not an ALE as defined for purposes of the employer shared responsibility penalties under Code §4980H(c)(2); and the employer does not offer a “group health plan” (excepted benefits are counted for this purpose) to any of its employees.",
					"dateCreated": "2024-10-05T14:30:00+00:00",
				},
				"datePublished": "2024-10-05T14:30:00+00:00",
				"dateModified": "2024-10-05T14:30:00+00:00",
			},
		]
	}
	return (
		<>
			<Helmet>
				<title>Salusion - Employer Resources</title>
				<meta name="title" content="FAQ: What are the employer requirements to offer a QSEHRA?" />
				<meta name="description" content="An employer is eligible to adopt a QSEHRA only if both of the following requirements are met: the employer is not an ALE as defined for purposes of the employer shared responsibility penalties under Code §4980H(c)(2); and the employer does not offer a “group health plan” (excepted benefits are counted for this purpose) to any of its employees." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>

			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							An employer is eligible to adopt a QSEHRA only if both of the following requirements are met:
						</p>

						<ul>
							<li>
								the employer is not an ALE as defined for purposes of the employer shared responsibility penalties under
								<a href="https://www.law.cornell.edu/uscode/text/26/4980H">Code §4980H(c)(2)</a>; and
							</li>

							<li>
								the employer does not offer a “group health plan” (excepted benefits are counted for this purpose) to
								any of its employees.
							</li>
						</ul>

						<p className="text">
							Under Code §4980H, ALE status is determined on a controlled group basis. For an employer to satisfy the
							first requirement, then, the entire controlled group that includes the QSEHRA sponsor must collectively
							employ fewer than 50 full-time-equivalent employees in the prior calendar year. An employer whose
							workforce exceeds 50 full-time employees for the first time in a calendar year will not be considered
							ineligible to sponsor a QSEHRA until January 1 of the following calendar year. IRS guidance clarifies that
							an employer is not an eligible employer for any month in which it offers a group health plan to employees
							that would provide coverage on any day of the month. Because the controlled group rules also apply when
							determining if the QSEHRA sponsor offers a group health plan, all controlled group members are precluded
							from offering a QSEHRA if any controlled group member offers a group health plan to employees.
						</p>

						<p className="text">
							While a group health plan is disqualifying if it is offered to employees, a group health plan offered to
							former employees (e.g., retirees) will not disqualify an employer from sponsoring a QSEHRA. Likewise, an S
							corporation will not fail to be an eligible employer if, separate from the QSEHRA, it reimburses the
							health insurance premiums of an employee who is also a more-than-2% shareholder.
						</p>

						<p className="text">
							Although the statutory QSEHRA provisions do not define the term “group health plan” for this purpose, IRS
							guidance clarifies that the term is broadly defined to include not only major medical coverage, but also
							health FSAs, HRAs, and plans that provide only excepted benefits (e.g., limited-scope dental and vision
							plans and indemnity coverage). Furthermore, employer endorsement of a particular form, policy, or issuer
							of individual health insurance may constitute a group health plan. On the other hand, contributing to an
							HSA (or permitting an employee to make pre-tax HSA contributions by salary reduction through a cafeteria
							plan) will not disqualify an employer from offering a QSEHRA.
						</p>

						<p className="text">
							IRS guidance also explains that allowing employees to have continued access to amounts that were
							accumulated in an HRA in prior years, or health FSA carryovers, will disqualify an employer from offering
							a QSEHRA. However, HRA accumulations from prior years will not cause the employer to fail to be an
							eligible employer if the employer suspends employees’ access to the HRA accumulations (such that the
							accumulations cannot be used for any purpose) during the period when the QSEHRA is provided.
						</p>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideQsehra}
					title="Provide tax-advantaged benefits simply and affordably with Salusion"
					pageLink="/product/qsehra"
				/>

				<ResourceCenterVideo
					resourceImg={ichraVsQsehra}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>
				<ResourceCenterVideo
					resourceImg={qsehraRules}
					title="Take a deep dive into the rules and regulations that govern QSEHRAs"
					pageLink="/product/qsehra/rules-and-regulations"
				/>
			</ResourceCenter>
		</>
	)
}
