import Accordion from "react-bootstrap/Accordion"

export default function EmployerCalculatorAccordion() {
	return (
		<>
			<section id="calculator_accordion">
				<div className="container">
					<div className="white-wrapper-all">
						<Accordion>
							<Accordion.Item eventKey="0">
								<Accordion.Header>Individuals Who Can Participate in an ICHRA</Accordion.Header>
								<Accordion.Body>
									<h4>Employees With Individual Coverage Insurance or Medicare</h4>
									<p>
										Employees are eligible to participate in an ICHRA if they are enrolled in one of the following
										health plans for each month that they are covered by the ICHRA.
									</p>
									<ul>
										<li>
											Health insurance purchased in the individual market that adheres to health care reform rules,
											specifically the prohibition on lifetime and annual dollar limits (PHSA §2711) and the preventive
											services mandate (PHSA §2713)
										</li>
										<li>Medicare (Parts A and B or Part C)</li>
									</ul>
									<h4>Owners Are Not Employees</h4>
									<p>The following individuals don't qualify as employees and cannot participate in an ICHRA:</p>
									<ul>
										<li>Sole proprietors</li>
										<li>Partners</li>
										<li>
											More-than-2% S corporation shareholders (more-than-2% shareholder's children, dependents, parents,
											and grandparents also cannot participate in the S corporation's ICHRA)
										</li>
										<li>Nonemployee independent contractors</li>
										<li>Nonemployee owners (employee owners of C corporations and LLCs can participate)</li>
									</ul>
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="1">
								<Accordion.Header>ICHRA Classes</Accordion.Header>
								<Accordion.Body>
									<h4>ICHRAs Are Offered to a Class of Employees</h4>
									<p>
										ICHRAs are offered to a class of employees on the same terms and conditions to all employees within
										the class. For the purpose of an ICHRA, employees can be segmented into the following classes:
									</p>
									<ul>
										<li>Full-time employees</li>
										<li>Part-time employees</li>
										<li>Employees who are paid on a salaried basis</li>
										<li>Non-salaried employees (e.g., hourly employees)</li>
										<li>Employees whose primary site of employment is in the same rating area</li>
										<li>Seasonal employees</li>
										<li>
											Employees included in a unit covered by a particular collective bargaining agreement (or an
											appropriate related participation agreement) in which the ICHRA sponsor participates, as described
											under <a href="https://www.law.cornell.edu/cfr/text/26/1.105-11">Code §105</a>
										</li>
										<li>
											Employees who have not satisfied a waiting period for coverage, if the waiting period complies
											with health care reform's waiting period requirements
										</li>
										<li>Nonresident aliens with no U.S.-based income</li>
										<li>Employees hired for temporary placement at an unrelated entity</li>
										<li>
											Employees who are in a combination of two or more of the classes (e.g., full-time employees
											covered by a particular collective bargaining agreement).
										</li>
									</ul>
									<p>
										Participants may be treated as belonging to a class of employees based on whether they are, or are
										not, included in one of the above classes.
									</p>
									<h4>Classes Determined at Common Law Employer Level</h4>
									<p>
										Classes are determined at the common-law employer level, rather than on a controlled group basis
										(i.e., without regard to the rules under Code §414(b), (c), (m), and (o) that would treat a
										common-law employer and other entities as a single employer). This allows different employers within
										a controlled group to vary benefits between affiliates with respect to classifications that could be
										considered a single class within a single employer.
									</p>
									<h4>Cannot Offer Group Health and an ICHRA to the Same Class of Employees</h4>
									<p>
										The ICHRA sponsor cannot offer a group health plan to the same class of employees that is offered an
										ICHRA. However, a special rule for new hires allows employers to offer a traditional plan to current
										employees within a class while offering an ICHRA to employees in the class who are hired on or after
										a certain future date.
									</p>
									<h4>Offering Group Health Invokes Minimum Class Sizes</h4>
									<p>
										If the ICHRA sponsor offers a group health plan to any class of employees and offers an ICHRA to one
										or more other classes of employees, then a minimum-class-size requirement to certain classes of
										employees that are offered an ICHRA. (The requirement does not apply to classes of employees that
										are offered a traditional health plan or are offered no coverage). The following classes are subject
										to the minimum-class-size requirement if they are offered an ICHRA:
									</p>
									<ul>
										<li>Full-time employees, if part-time employees are offered a traditional health plan</li>
										<li>Part-time employees, if full-time employees are offered a traditional health plan</li>
										<li>Salaried employees</li>
										<li>Non-salaried employees</li>
										<li>
											Employees whose primary site of employment is in the same rating area, unless the rating area is a
											state or a combination of two or more entire states
										</li>
										<li>
											A class of employees created by combining at least one of the preceding classes with any other
											class.
										</li>
									</ul>
									<p>
										However, the requirement does not apply if the class is a combination of one of the preceding
										classes and a class of employees who have not satisfied a waiting period for coverage. If the
										special rule for new hires is used, the minimum-class-size requirement will not apply to the new
										hire subclass unless the subclass is later subdivided, in which case the requirement will apply if
										it would otherwise be applicable.
									</p>
									<p>
										If a class of employees is subject to the minimum-class-size requirement, then the class must
										consist of a minimum number of employees in order for the ICHRA sponsor to treat that as a separate
										class of employees. The minimum number of employees that must be in a class of employees subject to
										the minimum-class-size requirement is as follows:
									</p>
									<ul>
										<li>10 employees, for an employer with fewer than 100 employees</li>
										<li>
											A number (rounded down to a whole number) equal to 10% of the total number of employees, for an
											employer with 100 to 200 employees
										</li>
										<li>20 employees, for an employer with more than 200 employees.</li>
									</ul>
									<p>
										The applicable number is determined prior to the beginning of each plan year for that year based on
										the number of employees that the employer reasonably expects to employ on the first day of the plan
										year. Whether a class has the minimum required number of employees is based on the number of
										employees in the class who are offered the ICHRA as of the first day of the plan year (not the
										number who actually enroll in the ICHRA). The determination is not affected by changes in the number
										of employees in the class during the plan year.
									</p>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
				</div>
			</section>
		</>
	)
}
