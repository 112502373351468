import * as Yup from "yup"

export default [
	Yup.object({ hasInsurance: Yup.string().required("Insurance Status is required") }),
	Yup.object({}),
	Yup.object({ insuranceProof: Yup.string().required("Insurance Proof is required") }),

	Yup.object({
		provider: Yup.string().required("Insurance Carrier is required"),
		policyId: Yup.string().required("Policy ID is required"),
		monthlyPremium: Yup.string().required("Monthly Premium is required"),
		policyHolder: Yup.string().required("Policy Holder is required")
	}),

	Yup.object({
		insuranceAttest: Yup.boolean()
			.oneOf([true], "Attestation is required")
			.required("You must confirm the attestation.")
	})
]
