import { Link } from "react-router-dom"
import "../styles.css"
import { Helmet } from "react-helmet-async"

export const question = "What is the difference between a GCHRA and an EBHRA?"

export default function LearningEntry() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": [
			{
				"@type": "Question",
				"name": "What is the difference between a GCHRA and an EBHRA?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "A GCHRA and an EBHRA are offered to employees to enhance Group Insurance coverage",
					"dateCreated": "2024-10-05T14:30:00+00:00",
				},
				"datePublished": "2024-10-05T14:30:00+00:00",
				"dateModified": "2024-10-05T14:30:00+00:00",
			},
		]
	}
	return (
		<>
			<Helmet>
				<title>Salusion - Employer Resources</title>
				<meta name="title" content="FAQ: What is the difference between a GCHRA and an EBHRA?" />
				<meta name="description" content="A GCHRA and an EBHRA are offered to employees to enhance Group Insurance coverage" />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>

			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">A Group Coverage HRA (GCHRA) and an Excepted Benefit HRA (EBHRA) are offered to employees to enhance Group Insurance coverage.  Employers are required to offer Group Coverage to offer these benefits.
                            </p>
                            <p>A GCHRA has no annual limit. The employee must participate in the Group Coverage plan to receive GCHRA benefits.
                            </p>
                            <p>An EBHRA has an annual limit ($2,150 in 2025).  The employee does not have to accept Group Coverage to receive EBHRA benefits.
                            </p>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
		</>
	)
}
