export default function Input(props) {
	const { type, name, label, placeholder, value, onChange } = props

	return (
		<div className="col-12">
			<div className="form-group">
				<label htmlFor={name}>{label}</label>
				<input
					className="form-control"
					type={type}
					name={name}
					placeholder={placeholder}
					value={value}
					onChange={(event) => {
						if (onChange) onChange(event)
					}}
				/>
			</div>
		</div>
	)
}
