import "../components/Products/Products.css"
import HraContact from "../components/Hra/HraContact"
import PriceComparison from "../components/Products/PriceComparison"
import ResourceCenterVideo from "../components/Resource-Center/ResourceCenterVideo"
import ResourceCenter from "../components/Resource-Center/ResourceCenter"

import ichravsqshera from "../assets/ichravsqsehra-poster.svg"
import ichraRules from "../assets/ichraRules.svg"
import employees from "../assets/50-employees.svg"
import ichraCalculator from "../assets/ichra-calculator.svg"
import reimbursement from "../assets/reimbursement-options.svg"
import guideIchra from "../assets/guide-to-ichra.svg"

import { PRICING_DATA_ICHRA } from "../components/Products/data"
import { TABLE_DATA_ICHRA } from "../components/Products/data"
import { Helmet } from "react-helmet-async"

export default function IchraConsultation() {
    const schema = {
        "@context": "https://schema.org",
        "@type": "Event",
        "name": "Book an ICHRA Consultation",
        "description": "Schedule a consultation with an ICHRA expert to learn how to create and manage an ICHRA for your business. Get personalized advice on employee reimbursement options and compliance.",
        "location": {
            "@type": "Place",
            "name": "Virtual Meeting Room",
        },
        "offers": {
            "@type": "Offer",
            "price": "14.00 / participant / month",
            "priceCurrency": "USD",
            "priceSpecification": {
                "@type": "UnitPriceSpecification",
                "price": "14.00",
                "priceCurrency": "USD",
                "billingDuration": 1,
                "billingIncrement": 1,
                "unitText": "participant/month",
            },
            "eligibleQuantity": {
                "@type": "QuantitativeValue",
                "value": 1,
                "unitText": "participant"
            },
            "shippingDetails": {
                "@type": "OfferShippingDetails",
                "shippingRate": {
                    "@type": "MonetaryAmount",
                    "value": "0.00",
                    "currency": "USD"
                },
                "shippingDestination": {
                    "@type": "DefinedRegion",
                    "name": "United States"
                },
                "deliveryTime": {
                    "@type": "QuantitativeValue",
                    "value": "0",
                    "unitCode": "DAY"
                }
            }
        },
        "performer": {
            "@type": "Person",
            "name": "Salusion Expert",
            "url": "https://salusion.com"
        },
        "registrationURL": "https://calendly.com/salusion-inquiry/salusion-support?month=2024-10"
    }
    return (
        <>
            <Helmet>
                <title>Salusion - ICHRA Consultation</title>
                <meta name="title" content="Book an ICHRA Consultation | Salusion" />
                <meta name="description" content="Schedule a consultation with an ICHRA expert to learn how to create and manage an ICHRA for your business. Get personalized advice on employee reimbursement options and compliance." />
                <script type="application/ld+json">{JSON.stringify(schema)}</script>
            </Helmet>

            <section id="gsehra_rules" className="first-section-full-background first-padding rules-all">
                <div className="container">
                    <h1>Book an ICHRA Consultation</h1>
                    <h3 style={{ color: "#ffffff" }}>Schedule a consultation with an ICHRA expert to learn how to create and manage an ICHRA for your business. Get personalized advice on employee reimbursement options and compliance.
                    </h3>
                    <br />
                    <div className="products-hra-wrapper">
                        <HraContact />
                    </div>
                </div>
            </section>
            <ResourceCenter>
                <ResourceCenterVideo
                    resourceImg={guideIchra}
                    title="A flexible, low-cost alternative to group coverage"
                    pageLink="/product/ichra/video-guide"
                />
                <ResourceCenterVideo
                    resourceImg={ichravsqshera}
                    title="Discover which HRA best fits your small business"
                    pageLink="/product/ichra-vs-qsehra"
                />
                <ResourceCenterVideo
                    resourceImg={ichraRules}
                    title="Take a deep dive into the rules and regulations that govern ICHRAs"
                    pageLink="/product/ichra/rules-and-regulations"
                />
                <ResourceCenterVideo
                    resourceImg={employees}
                    title="ICHRA topics for large employers"
                    pageLink="/product/ichra/large-business"
                />
                <ResourceCenterVideo
                    resourceImg={ichraCalculator}
                    title="Discover how much you can save with an ACA-compliant ICHRA"
                    externalLink="product/ichra/aca-compliant-calculator"
                />
                {/* <ResourceCenterVideo
					resourceImg={findBroker}
					title="Find a broker in your state that can help your employee find insurance"
					pageLink="/product/ichra/find-brokers"
				/> */}
                <ResourceCenterVideo
                    resourceImg={reimbursement}
                    title="Discussion of the pros and cons of each reimbursement option"
                    pageLink="/product/ichra/reimbursement-options"
                />
            </ResourceCenter>
            <PriceComparison
                dataPricing={PRICING_DATA_ICHRA}
                dataCheckmark={TABLE_DATA_ICHRA}
                title="ICHRA Price Comparison"
            />
        </>
    )
}
