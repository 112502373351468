export default {
	hasInsurance: "",
	provider: "",
	policyId: "",
	monthlyPremium: "",
	policyHolder: "",
	insuranceSource: "MARKETPLACE",
	insuranceProof: "",
	insuranceAttest: ""
}
