export default function PricingTab({ title, price, smallHeadingFirst, smallTextOne, smallHeadingSec, smallTextSec }) {
	return (
		<div className="col-12 col-lg-6 col-md-12">
			<div className="pricing-single">
				<div className="pricing-heading">
					<h2>{title}</h2>
				</div>
				<div className="price-content">
					<div className="price-wrapper-parent">
						<span>${price}</span>
						<p>/ participant / month</p>
					</div>
					<h3>{smallHeadingFirst}</h3>
					<p>{smallTextOne}</p>
					<h3>{smallHeadingSec}</h3>
					<p>{smallTextSec}</p>
				</div>
			</div>
		</div>
	)
}
