import { useState } from "react"
// import ButtonSlider from "./ButtonsSlider"

import EmployersSlider from "./EmployersSlider"
import WorkersSlider from "./WorkersSlider"
import SliderCustomArrows from "./SliderCustomArrows"

export default function Testimonials() {
	const [currSlide, setCurrSlide] = useState("employers")

	// function activeHandle(value) {
	//   setCurrSlide(value)
	// }

	return (
		<>
			<section id="testimonials">
				<div className="container mb-3 d-flex flex-row justify-content-between">
					<h2>Explore Our Community</h2>
					<SliderCustomArrows />

					{/* <div className="slider-button-wrapper">
            <div className="slider-switch-buttons">
              <ButtonSlider
                className="active"
                isActive={currSlide === "employers"}
                onClick={() => activeHandle("employers")}
              >
                Employers
              </ButtonSlider>
              
              <ButtonSlider
                className="active"
                isActive={currSlide === "workers"}
                onClick={() => activeHandle("workers")}
              >
                Workers
              </ButtonSlider>
            </div>

            <SliderCustomArrows />
          </div> */}
				</div>

				<div className="slider-wrapper">{currSlide === "employers" ? <EmployersSlider /> : <WorkersSlider />}</div>
			</section>
		</>
	)
}
