import { Link } from "react-router-dom"

import arrowRight from "../../../assets/arrow-right.svg"

export default function PackageSingle({ title, smallHeading, text, price, img, link }) {
	return (
		<>
			<div className="col-12 col-lg-3 col-md-6">
				<div className="package-single">
					<div className="package-heading">
						<img src={img} alt="ichra" />
						<h3>{title}</h3>
					</div>
					<div className="separator"></div>
					<h4>{smallHeading} </h4>
					<p>{text}</p>
					<div className="price-wrapper">
						<span>${price}/</span>
						<p>pepm</p>
					</div>
					<Link to={link}>
						Learn about {title}s <img src={arrowRight} alt="learn about hra" />
					</Link>
				</div>
			</div>
		</>
	)
}
