import { Link } from "react-router-dom"
import "../styles.css"
import { Helmet } from "react-helmet-async"
import guideIchra from "../../../assets/guide-to-ichra.svg"
import guideQsehra from "../../../assets/guide-salusions.svg"
import ichravsqshera from "../../../assets/ichravsqsehra-poster.svg"
import ResourceCenter from "../../../components/Resource-Center/ResourceCenter"
import ResourceCenterVideo from "../../../components/Resource-Center/ResourceCenterVideo"

export const question = "What is a premium tax credit?"

export default function LearningEntry() {
	const schema = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": [
			{
				"@type": "Question",
				"name": "What is a premium tax credit?",
				"acceptedAnswer": {
					"@type": "Answer",
					"text": "The premium tax credit is a financial assistance program provided by the government to help eligible individuals and their families afford health insurance premiums when they purchase coverage through the Health Insurance Marketplace, also known as the Exchange. This tax credit is specifically designed to make healthcare more affordable for those who meet certain criteria.",
					"dateCreated": "2024-10-05T14:30:00+00:00",
				},
				"datePublished": "2024-10-05T14:30:00+00:00",
				"dateModified": "2024-10-05T14:30:00+00:00",
			},
		]
	}

	return (
		<>
			<Helmet>
				<title>Salusion - Employer Resources</title>
				<meta name="title" content="FAQ: What is a premium tax credit?" />
				<meta name="description" content="The premium tax credit is a financial assistance program provided by the government to help eligible individuals and their families afford health insurance premiums when they purchase coverage through the Health Insurance Marketplace, also known as the Exchange. This tax credit is specifically designed to make healthcare more affordable for those who meet certain criteria." />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>

			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Learning Center</h1>

					<div className="entry-container white-wrapper-all">
						<h3>{question}</h3>
						<hr className="divider" />

						<p className="text">
							The premium tax credit is a financial assistance program provided by the government to help eligible
							individuals and their families afford health insurance premiums when they purchase coverage through the
							Health Insurance Marketplace, also known as the Exchange. This tax credit is specifically designed to make
							healthcare more affordable for those who meet certain criteria.
						</p>

						<p className="text">Here are some key points to understand about the premium tax credit:</p>

						<p className="text">
							<b>ELigibilty</b>: To qualify for the premium tax credit, you need to meet certain criteria. These
							criteria typically involve your income, household size, and your ability to get health insurance through
							your employer. It's important to note that the credit is generally not available if you can get health
							insurance through your employer or other government programs like Medicaid or Medicare.
						</p>

						<p className="text">
							<b>Income-Based</b>: The amount of the premium tax credit you receive is primarily based on your household
							income. Generally, the lower your income, the higher the credit you may be eligible for. This is because
							the government wants to ensure that health insurance is affordable for people with lower incomes.
						</p>

						<p className="text">
							<b>Purchase through the Exchange</b>: To access the premium tax credit, you must purchase your health
							insurance coverage through the Health Insurance Marketplace or Exchange. You cannot claim this credit if
							you buy insurance directly from an insurance company or through other means.
						</p>

						<p className="text">
							<b>Advance Payments</b>: In addition to claiming the premium tax credit when you file your annual tax
							return, the Exchange can also determine if you're eligible for advance payments of the premium tax credit.
							These are payments made directly to your insurance company on your behalf to reduce the monthly cost of
							your insurance premiums. This can make health insurance more affordable throughout the year rather than
							just at tax time.
						</p>

						<p className="text">
							<b>Employer Coverage</b>: If your employer offers you health insurance, including through a Health
							Reimbursement Arrangement (HRA), it may limit your ability to claim the premium tax credit. The government
							encourages people to use employer-offered coverage when available, so the credit is generally intended for
							those without affordable employer-based options.
						</p>

						<p className="text">
							The premium tax credit is aimed at making healthcare more accessible and affordable. For specific details
							and requirements, you can visit the official IRS website at irs.gov/aca.
						</p>

						<Link to="/learning-center" className="link">
							<i className="back-icon bi bi-arrow-left" />
							Back To Learning Center
						</Link>
					</div>
				</div>
			</section>
			<ResourceCenter>
				<ResourceCenterVideo
					resourceImg={guideIchra}
					title="A flexible, low-cost alternative to group coverage"
					pageLink="/product/ichra/video-guide"
				/>
				<ResourceCenterVideo
					resourceImg={guideQsehra}
					title="Provide tax-advantaged benefits simply and affordably with Salusion"
					pageLink="/product/qsehra/video-guide"
				/>
								<ResourceCenterVideo
					resourceImg={ichravsqshera}
					title="Discover which HRA best fits your small business"
					pageLink="/product/ichra-vs-qsehra"
				/>

			</ResourceCenter>

		</>
	)
}
