import React from "react"
import { Table } from "react-bootstrap"

export default function DataTable({ dataTableCheckmark }) {
	return (
		<Table responsive className="table-all">
			<tbody>
				{dataTableCheckmark.map((row, rowIndex) => (
					<tr key={rowIndex}>
						<td>{row.cell1}</td>
						<td>
							<img src={row.cell2} alt="hra comparison" />
						</td>
						<td>
							<img src={row.cell3} alt="" />
						</td>
						<td>
							<img src={row.cell4} alt="" />
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	)
}
