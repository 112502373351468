import { Helmet } from "react-helmet-async"
import Contact from "../components/Contact/Contact"

export default function ContactUs() {
	return (
		<>
			<Helmet>
				<title>Salusion - Contact Us</title>
			</Helmet>
			<Contact />
		</>
	)
}
