import React, { useContext, useState, useEffect } from "react"
import { Formik, Form } from "formik"

import { OnboardingContext } from "../../context"
import initialValues from "./initialValues"
import validation from "./validation"

import { InsuranceType, Participation, Affordability } from "./steps"
import { Agreement, CreateAccount, Ineligible } from "./steps"

export default function OnboardingForm() {
	const { setCurrentForm, setCurrentStep, setOnboarding } = useContext(OnboardingContext)

	const [step, setStep] = useState(() => 0)
	const [steps, setSteps] = useState(() => [InsuranceType])
	const [insuranceType, setInsuranceType] = useState(() => "INDIVIDUAL_COVERAGE")

	function handleSubmit(onboarding) {
		const insuranceType = onboarding.insuranceType
		const lastStep = steps.length - 1

		if (step === 0) {
			setNextSteps(insuranceType)
			setStep(step + 1)
		} else if (step < lastStep) {
			setStep(step + 1)
		} else if (step === lastStep) {
			setCurrentForm("insurance")
			setCurrentStep(0)

			console.log("onboarding form complete", onboarding)
		}

		setOnboarding(onboarding)
	}

	function setNextSteps(insuranceType) {
		if (insuranceType === "INDIVIDUAL_COVERAGE") {
			setSteps([InsuranceType, Participation, Affordability, Agreement, CreateAccount])
		} else if (insuranceType === "MEDICARE") {
			setSteps([InsuranceType, Agreement, CreateAccount])
		} else if (insuranceType === "GROUP_COVERAGE") {
			setSteps([InsuranceType, Ineligible])
		} else if (insuranceType === "OTHER") {
			setSteps([InsuranceType, Ineligible])
		} else if (insuranceType === "NONE") {
			setSteps([InsuranceType, Ineligible])
		}

		setInsuranceType(insuranceType)
	}

	useEffect(() => {
		const currentStep = steps[step]

		if (currentStep === InsuranceType) {
			setCurrentStep(0)
		} else if (currentStep === Participation) {
			setCurrentStep(1)
		} else if (currentStep === Agreement) {
			setCurrentStep(1)
		} else if (currentStep === CreateAccount) {
			setCurrentStep(2)
		}
	}, [setCurrentStep, step, steps])

	return (
		<Formik initialValues={initialValues} validationSchema={validation[step]} onSubmit={handleSubmit}>
			<Form>
				{React.createElement(steps[step], {
					step,
					setStep,

					steps,
					setSteps,

					insuranceType
				})}
			</Form>
		</Formik>
	)
}
