import React, { useContext, useState, useEffect } from "react"
import { Formik, Form } from "formik"

import { OnboardingContext } from "../../context"
import initialValues from "./initialValues"
import validation from "./validation"

import { InsuranceStatus, Marketplace, Dependents } from "./steps"
import { InsuranceProof, InsuranceDetails, Attestation } from "./steps"

export default function InsuranceForm() {
	const { setCurrentForm, setCurrentStep, setInsurance, setSubmittedDependents } = useContext(OnboardingContext)

	const [step, setStep] = useState(() => 0)
	const [steps, setSteps] = useState(() => [InsuranceStatus])
	const [hasInsurance, setHasInsurance] = useState(() => "")
	const [dependents, setDependents] = useState(() => [])
	const [updatedDependent, setUpdatedDependent] = useState(() => null)

	function addDependent(addedDependent) {
		if (updatedDependent) {
			const updatedDependents = dependents.map((dependent) =>
				dependent.id === addedDependent.id ? addedDependent : dependent
			)

			setDependents(updatedDependents)
			setUpdatedDependent(null)
		} else {
			setDependents([...dependents, addedDependent])
		}
	}

	function updateDependent(dependent) {
		setUpdatedDependent(dependent)
		setStep(2)
	}

	function deleteDependent(dependentId) {
		setDependents(dependents.filter((dependent) => dependent.id !== dependentId))
	}

	function handleSubmit(insurance) {
		const hasInsurance = insurance.hasInsurance
		const lastStep = steps.length - 1

		if (step === 0) {
			setNextSteps(hasInsurance)
			setStep(step + 1)
		} else if (step < lastStep) {
			setStep(step + 1)
		} else if (step === lastStep) {
			setCurrentForm("reimbursement")
			setCurrentStep(0)

			console.log("insurance form complete", insurance)
		}

		setInsurance(insurance)
		setSubmittedDependents(dependents)
	}

	function setNextSteps(hasInsurance) {
		if (hasInsurance === "Yes") {
			setSteps([InsuranceStatus, Dependents, InsuranceProof, InsuranceDetails, Attestation])
		} else {
			setSteps([InsuranceStatus, Marketplace])
		}

		setHasInsurance(hasInsurance)
	}

	useEffect(() => {
		const currentStep = steps[step]

		if (currentStep === InsuranceStatus) {
			setCurrentStep(0)
		} else if (currentStep === Dependents) {
			setCurrentStep(1)
		} else if (currentStep === InsuranceProof) {
			setCurrentStep(2)
		}
	}, [setCurrentStep, step, steps])

	return (
		<div className="insurance-form">
			<Formik initialValues={initialValues} validationSchema={validation[step]} onSubmit={handleSubmit}>
				<Form>
					{React.createElement(steps[step], {
						step,
						setStep,

						steps,
						setSteps,

						hasInsurance,
						dependents,
						updatedDependent,
						setUpdatedDependent,

						addDependent,
						updateDependent,
						deleteDependent
					})}
				</Form>
			</Formik>
		</div>
	)
}
