import { Field, ErrorMessage } from "formik"
import FormButtons from "../../FormButtons"

export default function InsuranceType({ step, setStep }) {
	return (
		<>
			<div className="form-content-wrapper">
				<h2>ICHRA Eligibility</h2>

				<h3>
					What type of health coverage will you have during the ICHRA plan year from [Employee_Plan_Year_Start_Date] to
					[Employee_Plan_Year_End_Date]?
				</h3>

				<h4>Please select one:</h4>

				<div className="form-control radio-buttons">
					<label>
						<Field type="radio" name="insuranceType" value="INDIVIDUAL_COVERAGE" />
						<span>Individual Coverage</span>
					</label>

					<label>
						<Field type="radio" name="insuranceType" value="MEDICARE" />
						<span>Medicare</span>
					</label>

					<label>
						<Field type="radio" name="insuranceType" value="GROUP_COVERAGE" />
						<span>Group coverage through spouse or parent</span>
					</label>

					<label>
						<Field type="radio" name="insuranceType" value="OTHER" />
						<span>Other insurance types (COBRA, VA, Tricare, etc...)</span>
					</label>

					<label>
						<Field type="radio" name="insuranceType" value="NONE" />
						<span>I do not plan on being covered during this period</span>
					</label>

					<ErrorMessage name="insuranceType" component="div" className="error-message" />
				</div>
			</div>

			<FormButtons step={step} setStep={setStep} />
		</>
	)
}
