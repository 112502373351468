import { Helmet } from "react-helmet-async"

import "../components/Homepage/Homepage.css"

import Hero from "../components/Homepage/Hero"
import CompanyPackages from "../components/Homepage/company-packages/CompanyPackages"
import SalusionSimplifies from "../components/Homepage/salusion-simplifies/SalusionSimplifies"
import Testimonials from "../components/Homepage/testimonials/Testimonials"
import Benefits from "../components/Homepage/benefits/Benefits"
import Hra from "../components/Hra/HraContact"

export default function Homepage() {
	return (
		<>
			<Helmet>
				<title>Salusion</title>
			</Helmet>
			<Hero />
			<CompanyPackages />
			<SalusionSimplifies />
			<Benefits />
			<Testimonials />
			<Hra />
		</>
	)
}
