import { NavLink } from "react-router-dom"

export default function DropdownMenu({ text, link, img, clickMenu }) {
	return (
		<NavLink to={link} onClick={clickMenu}>
			<div className="dropdown-menu-icon">
				<img src={img} alt={text} />
			</div>
			<div className="dropdown-menu-text">{text}</div>
		</NavLink>
	)
}
