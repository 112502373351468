import { Field, ErrorMessage } from "formik"
import FormButtons from "../../FormButtons"

export default function InsuranceStatus({ step, setStep }) {
	return (
		<>
			<div className="form-content-wrapper">
				<h2>Insurance Status</h2>

				<h3>
					Do you currently have individual coverage health insurance or Medicare for any part of the plan year from
					[Employee_Plan_Year_Start_Date] to [Employee_Plan_Year_End_Date]?{" "}
				</h3>

				<div className="form-control radio-buttons">
					<label>
						<Field type="radio" name="hasInsurance" value="Yes" />
						<span>Yes</span>
					</label>

					<label>
						<Field type="radio" name="hasInsurance" value="No" />
						<span>No</span>
					</label>

					<ErrorMessage name="hasInsurance" component="div" className="error-message-fields" />
				</div>
			</div>

			<FormButtons step={step} setStep={setStep} />
		</>
	)
}
