import { Link } from "react-router-dom"
import Accordion from "react-bootstrap/Accordion"

import { ichraRoutes, qsehraRoutes, otherRoutes } from "./LearningEntries"

export default function LearningCenter() {
	const styles = {
		link: {
			marginBottom: "0.5rem",
			display: "block",
			fontSize: "20px",
			fontWeight: 500,
			color: "#465578"
		}
	}

	return (
		<>
			<section className="first-section-full-background first-padding rules-all">
				<div className="container">
					<h1>Salusion - Learning Center</h1>

					<div className="white-wrapper-all gsehra-rules-reg-wrapper">
						<h3>Expand one of the learning categories below to see their frequently asked questions.</h3>

						<div className="guide-accordion-all">
							<Accordion>
								<Accordion.Item eventKey="0">
									<Accordion.Header>Individual Coverage HRA</Accordion.Header>

									<Accordion.Body>
										{ichraRoutes.map((route, index) => (
											<Link key={index} to={route.path} style={styles.link}>
												{route.question}
											</Link>
										))}
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="1">
									<Accordion.Header>Qualified Small Employer HRA</Accordion.Header>

									<Accordion.Body>
										{qsehraRoutes.map((route, index) => (
											<Link key={index} to={route.path} style={styles.link}>
												{route.question}
											</Link>
										))}
									</Accordion.Body>
								</Accordion.Item>

								<Accordion.Item eventKey="2">
									<Accordion.Header>Other</Accordion.Header>

									<Accordion.Body>
										{otherRoutes.map((route, index) => (
											<Link key={index} to={route.path} style={styles.link}>
												{route.question}
											</Link>
										))}
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
