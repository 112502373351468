/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export default function Redirect() {
	const location = useLocation()
	const navigate = useNavigate()

	const redirectRoutes = {
		"/product/ichra/guide": "/product/ichra",
		"/product/qsehra/guide": "/product/qsehra",
		"/compare/product/qsehra-ichra": "/product/ichra-vs-qsehra",
		"/compare/prices/ichra": "/pricing",
		"/compare/prices/qsehra": "/pricing"
	}

	const route = location.pathname
	const redirectRoute = redirectRoutes[route] || "/"

	useEffect(() => {
		navigate(redirectRoute)
	}, [])
}
