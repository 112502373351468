import { useState } from "react"
import { useFormikContext, ErrorMessage } from "formik"
import FormButtons from "../../FormButtons"

export default function InsuranceProof({ step, setStep }) {
	const { setFieldValue } = useFormikContext()
	const [file, setFile] = useState(() => ({}))

	function uploadFile(event) {
		const file = event.currentTarget.files[0]

		setFile(file)
		setFieldValue("insuranceProof", file)
	}

	return (
		<>
			<div className="form-content-wrapper">
				<h2>Submit Proof of Insurance</h2>
				<h3>Upload your proof of health insurance</h3>
				<p>Please ensure that the dates of insurance coverage are included in your proof of health insurance.</p>

				<p>
					A monthly bill from your insurance provider, a screenshot of the confirmation page from your enrollment, an
					e-mail confirmation of your enrollment, your healthcare.gov (or state exchange) confirmation page, or even a
					screenshot from your health insurance online portal will be considered as Proof of Coverage.
				</p>

				<div className="add-document-wrapper">
					<label>
						<input type="file" name="insuranceProof" onChange={uploadFile} />
						<span>{file.name ? file.name : "+ Add Document"}</span>
					</label>

					<ErrorMessage name="insuranceProof" component="div" className="error-message-fields" />
				</div>
			</div>

			<FormButtons step={step} setStep={setStep} />
		</>
	)
}
